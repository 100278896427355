import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Button, Spinner } from 'react-bootstrap';
import Section from 'components/common/Section';
import Logo from 'components/common/Logo';
import Lottie from 'lottie-react';
import resend from '../../components/wizard/lottie/resend-email.json';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { selectUser, logout, setUser } from 'redux/reducer/auth';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const ResendConfirmationEmailPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [disabled, setDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(120);
  const [loading, setLoading] = useState(false);

  const handleResend = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.post(
        '/auth/resend-verification-email'
      );
      dispatch(setUser(response.data.user));
      setLoading(false);
      toast.success('Resend Confirmation Email Successful');
      setDisabled(true);
      setTimeLeft(120);
    } catch (error) {
      setLoading(false);
      console.error('Error resend email:', error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (disabled && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      setDisabled(false);
    }
  }, [disabled, timeLeft]);

  const formatDecimal = value => String(value).padStart(2, '0');

  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return [formatDecimal(minutes), formatDecimal(remainingSeconds)].join(':');
  };

  useEffect(() => {
    if (!user) return;
    const { resendCount, lastResendTime } = user;

    if (resendCount >= 3) {
      navigate('/contact-support');
    } else if (lastResendTime) {
      const lastResendTimeDate = dayjs(lastResendTime);
      const currentTime = dayjs(new Date());
      const diffInSeconds = currentTime.diff(lastResendTimeDate, 'second');

      if (diffInSeconds < 120) {
        setDisabled(true);
        setTimeLeft(120 - diffInSeconds);
      }
    }
  }, [user]);

  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
          <Logo />
          <Card className="theme-wizard text-center">
            {user ? (
              <Card.Body className="p-5">
                <Row>
                  <Col className="text-center">
                    <div className="wizard-lottie-wrapper">
                      <div className="wizard-lottie wizard-lottie-resend mx-auto">
                        <Lottie animationData={resend} loop={true} />
                      </div>
                    </div>
                  </Col>
                </Row>
                <p className="lead mt-4 text-800 font-sans-serif fw-semibold">
                  Resend Confirmation Email
                </p>
                <div className="col-12 d-flex flex-column gap-3 justify-content-center align-items-center px-4 mb-3">
                  <Button disabled={disabled} onClick={handleResend}>
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      'Resend'
                    )}
                  </Button>
                </div>
                {disabled && (
                  <p>
                    Please wait{' '}
                    <span
                      style={{
                        fontWeight: 'bold',
                        color: '#ff6347'
                      }}
                    >
                      {formatTime(timeLeft)}
                    </span>{' '}
                    before trying again. Check your inbox or spam folder for the
                    confirmation email. If the email is in spam, please mark it
                    as ‘Not Spam’ to ensure future email delivery.
                  </p>
                )}
              </Card.Body>
            ) : (
              <Row className="justify-content-center mb-3 g-3">
                <Col lg={4} className="d-flex justify-content-center">
                  <Lottie
                    animationData={infiniteLoop}
                    loop={true}
                    style={{ height: '120px', width: '120px' }}
                  />
                </Col>
                <h3 className="text-center">Loading...</h3>
              </Row>
            )}
          </Card>
          <Link
            to="/login"
            className="d-flex mx-auto mt-4 text-center fs-10"
            onClick={() => {
              dispatch(logout());
              navigate('/login');
            }}
            style={{ width: 'fit-content' }}
          >
            Back to login
          </Link>
        </Col>
      </Row>
    </Section>
  );
};

export default ResendConfirmationEmailPage;
