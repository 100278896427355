import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import SocialAuthButtons from './SocialAuthButtons';
import paths from 'routes/paths';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { login, selectError, selectIsLoading } from 'redux/reducer/auth';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import { useNavigate } from 'react-router-dom';

const forgotPasswordPaths = {
  simple: paths.simpleForgotPassword,
  split: paths.splitForgotPassword,
  card: paths.cardForgotPassword
};

const LoginForm = ({ hasLabel, layout }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoading);
  const error = useAppSelector(selectError);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  const handleSubmit = async e => {
    try {
      e.preventDefault();
      const resultAction = await dispatch(login(formData)).unwrap();
      if (!resultAction?.user?.isVerified) {
        if (resultAction?.user?.resendCount >= 3) {
          navigate('/contact-support');
        } else {
          navigate('/resend-confirmation-email');
        }
      } else {
        navigate('/select-organization');
      }
    } catch (err) {
      console.error('Login failed:', err);
      // toast.error('Invalid credentials');
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    if (error) {
      toast.error(`Failed to login: ${error.message}`, {
        theme: 'colored'
      });
    }
  }, [error]);

  if (isLoading) {
    return (
      <Row className="justify-content-center mb-3 g-3">
        <Col lg={4} className="d-flex justify-content-center">
          <Lottie
            animationData={infiniteLoop}
            loop={true}
            style={{ height: '120px', width: '120px' }}
          />
        </Col>
        <h3 className="text-center">Loading...</h3>
      </Row>
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link className="fs-10 mb-0" to={forgotPasswordPaths[layout]}>
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Log in
        </Button>
      </Form.Group>

      <Divider className="mt-4">or log in with</Divider>

      <SocialAuthButtons />
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
