import React from 'react';
import { Col, Placeholder, Row } from 'react-bootstrap';
import SubtleBadge from 'components/common/SubtleBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';

const EventCard = ({ event, rate, comparisonRate, loading, size }) => {
  const percentage = comparisonRate
    ? parseFloat(((rate - comparisonRate) / comparisonRate).toFixed(2)) * 100
    : rate > 0
    ? 100
    : 0;
  return (
    <Row className="flex-between-center">
      <Col
        md={size !== 'large' && 12}
        className="d-md-flex d-lg-block flex-between-center"
      >
        <h6 className="mb-md-0 mb-lg-2">
          {event}{' '}
          {size !== 'large' &&
            (loading ? (
              <Placeholder as="span" animation="glow">
                <Placeholder
                  xs={12}
                  style={{ width: '60px', height: '18px' }}
                />
              </Placeholder>
            ) : (
              <SubtleBadge
                bg={
                  percentage !== 0
                    ? percentage > 0
                      ? 'success'
                      : 'danger'
                    : 'secondary'
                }
                pill
              >
                <FontAwesomeIcon
                  icon={
                    percentage !== 0
                      ? percentage > 0
                        ? 'caret-up'
                        : 'caret-down'
                      : ''
                  }
                />{' '}
                {Math.abs(percentage)}%
              </SubtleBadge>
            ))}
        </h6>
        {size === 'large' &&
          (loading ? (
            <Placeholder as="div" animation="glow">
              <Placeholder xs={12} style={{ width: '60px', height: '25px' }} />
            </Placeholder>
          ) : (
            <SubtleBadge
              bg={
                percentage !== 0
                  ? percentage > 0
                    ? 'success'
                    : 'danger'
                  : 'secondary'
              }
              pill
            >
              <FontAwesomeIcon
                icon={
                  percentage !== 0
                    ? percentage > 0
                      ? 'caret-up'
                      : 'caret-down'
                    : ''
                }
              />{' '}
              {Math.abs(percentage)}%
            </SubtleBadge>
          ))}
      </Col>
      <Col xs="auto">
        {loading ? (
          <Placeholder as="h4" animation="glow">
            <Placeholder xs={12} style={{ width: 100, height: 40 }} />
          </Placeholder>
        ) : (
          <h4 className="fs-6 fw-normal text-primary">
            <CountUp
              start={0}
              end={rate}
              suffix="%"
              duration={2.75}
              decimals={2}
              decimal="."
            />
          </h4>
        )}
      </Col>
    </Row>
  );
};

EventCard.propTypes = {
  event: PropTypes.string,
  rate: PropTypes.number,
  comparisonRate: PropTypes.number,
  loading: PropTypes.bool,
  size: PropTypes.string
};

export default EventCard;
