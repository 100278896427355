import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Tab, Nav, Form, Placeholder } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import classNames from 'classnames';
import { emailEvents, periodType } from 'data/common';
import EventChart from './EventChart';

const TabTitle = ({ title, value, isLoading }) => (
  <div className="p-2 pe-4 text-start cursor-pointer">
    <h6 className="text-800 fs-11 text-nowrap text-capitalize ">{title}</h6>
    {isLoading ? (
      <Placeholder as="h5" animation="glow">
        <Placeholder xs={12} style={{ width: '50px' }} />
      </Placeholder>
    ) : (
      <h5 className="text-800" style={{ width: '50px' }}>
        {value}
      </h5>
    )}
  </div>
);

const Events = ({ chartData, className, onPeriodChange, loading, period }) => {
  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <Card.Header className="border-bottom">
        <h5 className="mb-0 mt-2">Email event</h5>
      </Card.Header>
      <Tab.Container id="Events-tab" defaultActiveKey={emailEvents[0]}>
        <SimpleBarReact>
          <Card.Header className="p-0 bg-body-tertiary">
            <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
              {emailEvents.map((event, index) => (
                <Nav.Item key={index}>
                  <Nav.Link className="mb-0" eventKey={event}>
                    <TabTitle
                      title={event}
                      value={
                        chartData.find(item => item.eventType === event)
                          ?.total || 0
                      }
                      isLoading={loading}
                    />
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>
        </SimpleBarReact>

        <Card.Body>
          <Tab.Content>
            {loading ? (
              <Placeholder as="Tab.Pane" animation="glow">
                <Placeholder xs={12} style={{ height: '21.25rem' }} />
              </Placeholder>
            ) : (
              emailEvents.map((event, index) => (
                <Tab.Pane unmountOnExit eventKey={event} key={index}>
                  <EventChart
                    data={
                      chartData.find(item => item.eventType === event)
                        ?.timeSeries || []
                    }
                    event={event}
                    period={period}
                  />
                </Tab.Pane>
              ))
            )}
          </Tab.Content>
        </Card.Body>
      </Tab.Container>

      <Card.Footer className="bg-body-tertiary py-2">
        <Row className="g-0 flex-between-center">
          <Col xs="auto">
            <Form.Select
              size="sm"
              className="me-2"
              onChange={e => {
                onPeriodChange(e.target.value);
              }}
            >
              <option>{periodType.WEEK}</option>
              <option>{periodType.MONTH}</option>
              <option>{periodType.YEAR}</option>
            </Form.Select>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  isLoading: PropTypes.bool.isRequired
};

Events.propTypes = {
  chartData: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  onPeriodChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default Events;
