import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from 'services/axios';

const initialState = {
  user: null,
  organization: null,
  organizations: [],
  token: localStorage.getItem('token'),
  error: '',
  isLoading: false
};

export const setToken = createAsyncThunk('token', data => {
  return data;
});

export const register = createAsyncThunk(
  'auth/register',
  async (valueForm, thunkAPI) => {
    try {
      const res = await axiosClient.post('/auth/register', valueForm);
      localStorage.setItem('token', res?.data.token);
      return res.data;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async (formValue, thunkAPI) => {
    try {
      const res = await axiosClient.post('/auth/login', formValue);
      localStorage.setItem('token', res?.data.token);
      return res.data;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  }
);

export const setOrganization = createAsyncThunk(
  'auth/set-organization',
  async (formValue, thunkAPI) => {
    try {
      const res = await axiosClient.post('/auth/set-organization', formValue);
      localStorage.setItem('token', res?.data.token);
      return res.data;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  }
);

export const getUser = createAsyncThunk('auth/user', async thunkAPI => {
  try {
    const res = await axiosClient.get('/auth/user');
    return res.data;
  } catch (error) {
    if (error.response.status === 400) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    if (error.response.status === 401) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
});

export const logout = createAsyncThunk('auth/logout', () => {
  localStorage.setItem('token', '');
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(setToken.fulfilled, (state, action) => {
        state.token = action.payload;
      })

      .addCase(register.pending, state => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.error = '';
        state.token = action.payload.token;
        state.isLoading = false;
      })
      .addCase(register.rejected, (state, action) => {
        state.token = '';
        state.error = action.payload;
        state.isLoading = false;
      })

      .addCase(login.pending, state => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.error = '';
        state.token = action.payload.token;
        state.user = action.payload.user;
        state.organizations = action.payload.organizations;
        state.isLoading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.token = '';
        state.user = null;
        state.organizations = [];
        state.error = action.payload;
        state.isLoading = false;
      })

      .addCase(setOrganization.pending, state => {
        state.isLoading = true;
      })
      .addCase(setOrganization.fulfilled, (state, action) => {
        state.error = '';
        state.token = action.payload.token;
        state.organization = action.payload.organization;
        state.isLoading = false;
      })
      .addCase(setOrganization.rejected, state => {
        state.token = '';
        state.isLoading = false;
      })

      .addCase(logout.pending, state => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, state => {
        state.token = '';
        state.organization = null;
        state.isLoading = false;
      })
      .addCase(logout.rejected, state => {
        state.isLoading = false;
      })

      .addCase(getUser.pending, state => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.error = '';
        state.token = action.payload.token;
        state.user = action.payload.user;
        state.organization = action.payload.organization;
        state.organizations = action.payload.organizations;
        state.isLoading = false;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.user = null;
        state.organization = null;
        state.organizations = [];
        state.token = '';
        state.error = action.payload;
        state.isLoading = false;
      });
  }
});

export const { setUser } = authSlice.actions;

export const selectToken = state => state.auth.token;
export const selectUser = state => state.auth.user;
export const selectOrganization = state => state.auth.organization;
export const selectOrganizations = state => state.auth.organizations;
export const selectIsLoading = state => state.auth.isLoading;
export const selectError = state => state.auth.error;

export default authSlice.reducer;
