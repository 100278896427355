import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosClient from 'services/axios';
import Logo from 'components/common/Logo';
import { Form, Button, Row, Col, Modal } from 'react-bootstrap';
import Lottie from 'lottie-react'; // <-- Import from 'lottie-react'

// Import your Lottie JSON
import thankYouAnimation from 'assets/img/animated-icons/thank-you.json';

const FEATURE_OPTIONS = [
  'AI segmentation',
  'email',
  'text',
  'social media',
  'monetize content',
  'access to campaign template',
  'website builder',
  'email templates'
];

const WaitListFormPage = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [showReCaptchaError, setShowReCaptchaError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('recaptchaToken');
    if (!token) {
      navigate('/waitlist-captcha');
    }
  }, [navigate]);

  const handleFeatureToggle = (feature, checked) => {
    setSelectedFeatures(prev => {
      if (checked) {
        return [...prev, feature];
      } else {
        return prev.filter(f => f !== feature);
      }
    });
  };

  const handleGoBackToCaptcha = () => {
    localStorage.removeItem('recaptchaToken');
    navigate('/waitlist-captcha');
  };

  const handleCloseModal = () => {
    setShowReCaptchaError(false);
  };

  const onSubmit = async data => {
    setIsLoading(true);
    try {
      const recaptchaToken = localStorage.getItem('recaptchaToken');
      if (!recaptchaToken) {
        toast.error('Please complete reCAPTCHA first.');
        setIsLoading(false);
        navigate('/waitlist-captcha');
        return;
      }

      // Combine selected features
      const featuresString = selectedFeatures.join(', ');

      const submissionData = {
        ...data,
        source: 'waitlist',
        features: featuresString,
        captchaToken: recaptchaToken
      };

      await axiosClient.post('/contacts/add-waitlist-contact', submissionData);

      toast.success('Form submitted successfully!');
      reset();
      localStorage.removeItem('recaptchaToken');
      setSelectedFeatures([]);
      setIsLoading(false);

      // Mark as submitted to show "Thank You" Lottie
      setIsSubmitted(true);
    } catch (error) {
      setIsLoading(false);
      const errorMsg = error.response?.data?.message || 'Submission failed.';
      if (/recaptcha|timeout-or-duplicate|expired/i.test(errorMsg)) {
        setShowReCaptchaError(true);
      } else {
        toast.error(errorMsg);
      }
    }
  };

  // If user has submitted, show the Lottie-based Thank You screen
  if (isSubmitted) {
    return (
      <div
        className="d-flex flex-column align-items-center justify-content-center bg-black text-white"
        style={{ minHeight: '100vh', padding: '1rem' }}
      >
        <Logo width={130} className="mb-4" />

        <h2 className="text-center mb-3">
          Thank you for joining our community!
        </h2>
        <p className="text-center mb-4" style={{ maxWidth: '600px' }}>
          We appreciate your interest and will keep you updated on the latest
          features and releases.
        </p>

        {/* Render the Lottie animation */}
        <div style={{ width: 400, height: 400 }}>
          <Lottie animationData={thankYouAnimation} loop={false} />
        </div>
      </div>
    );
  }

  // Otherwise, show the form
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center bg-black"
      style={{ minHeight: '100vh', padding: '1rem' }}
    >
      <Logo width={130} className="mb-4" />

      <div
        className="card shadow p-4"
        style={{ maxWidth: '600px', width: '100%' }}
      >
        <h2 className="text-center mb-4">Join Our Waitlist</h2>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Control
            type="hidden"
            defaultValue="waitlist"
            {...register('source')}
          />

          {/* SECTION 1: Personal Info */}
          <h5 className="mb-3">Contact Information</h5>
          <Form.Group className="mb-3" controlId="firstName">
            <Form.Label>First Name (Optional)</Form.Label>
            <Form.Control
              type="text"
              placeholder="John"
              {...register('firstName')}
              isInvalid={!!errors.firstName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="lastName">
            <Form.Label>Last Name (Optional)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Doe"
              {...register('lastName')}
              isInvalid={!!errors.lastName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email *</Form.Label>
            <Form.Control
              type="email"
              placeholder="john@example.com"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Invalid email address'
                }
              })}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-4" controlId="phoneNumber">
            <Form.Label>Phone Number (Optional)</Form.Label>
            <Form.Control
              type="text"
              placeholder="(555) 555-5555"
              {...register('phoneNumber')}
            />
          </Form.Group>

          <hr className="my-4" />

          {/* SECTION 2: Feature Interests */}
          <h5 className="mb-3">Feature Interests</h5>
          <Form.Group className="mb-4" controlId="features">
            <Row>
              {FEATURE_OPTIONS.map(feature => (
                <Col xs={12} sm={6} key={feature} className="mb-2">
                  <Form.Check
                    type="checkbox"
                    label={feature}
                    checked={selectedFeatures.includes(feature)}
                    onChange={e =>
                      handleFeatureToggle(feature, e.target.checked)
                    }
                  />
                </Col>
              ))}
            </Row>
          </Form.Group>

          <hr className="my-4" />

          {/* SECTION 3: Communication Preferences */}
          <h5 className="mb-3">Communication Preferences</h5>
          <Form.Group className="mb-3" controlId="textOptIn">
            <Form.Check
              type="checkbox"
              label="Opt-in to Texts"
              {...register('textOptIn')}
            />
          </Form.Group>

          <Form.Group className="mb-4" controlId="emailOptIn">
            <Form.Check
              type="checkbox"
              label="Opt-in to Emails"
              {...register('emailOptIn')}
            />
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            disabled={isLoading}
            className="w-100"
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </Button>
        </Form>
      </div>

      {/* Modal for reCAPTCHA error */}
      <Modal show={showReCaptchaError} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>reCAPTCHA Verification Expired</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your CAPTCHA token is invalid or expired. Please verify again.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleGoBackToCaptcha}>
            Go Back to Captcha
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WaitListFormPage;
