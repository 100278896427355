import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { contactPeriodType } from 'data/common';
import axiosClient from 'services/axios';
import EventCard from 'components/dashboard/events/EventCard';
import EventRates from 'components/dashboard/events/EventRates';

const Statistics = ({ campaign }) => {
  const [eventData, setEventData] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [eventPeriod, setEventPeriod] = useState(contactPeriodType.last7Days);

  const fetchEventStats = async () => {
    setLoadingEvents(true);
    try {
      const formattedPeriod = eventPeriod.replace(/\s+/g, '_').toLowerCase();
      const response = await axiosClient.get(
        `/campaign-events/analyze?period=${formattedPeriod}&campaignId=${campaign.id}`
      );
      setEventData(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoadingEvents(false);
  };

  useEffect(() => {
    fetchEventStats();
  }, [eventPeriod]);

  return (
    <div className="mt-3">
      <Card className="h-100 mb-4" style={{ boxShadow: 'none' }}>
        <Card.Header
          className="d-flex justify-content-between align-items-center"
          style={{ padding: '10px 20px' }}
        >
          <h6 className="mb-0">Events</h6>
          <Form.Select
            size="sm"
            className="me-2"
            onChange={e => {
              setEventPeriod(e.target.value);
            }}
            style={{ width: 'fit-content' }}
          >
            <option>{contactPeriodType.last7Days}</option>
            <option>{contactPeriodType.last30Days}</option>
            <option>{contactPeriodType.yearToDate}</option>
          </Form.Select>
        </Card.Header>
        <Card.Body className="py-0">
          <Row>
            <Col
              lg={4}
              md={12}
              className="py-3 border-end-0 border-lg-end border-bottom border-lg-bottom-0"
            >
              <EventCard
                loading={loadingEvents}
                event={'Click Rate'}
                rate={eventData?.data?.rate?.clickRate}
                comparisonRate={eventData?.comparisonData?.rate?.clickRate}
              />
            </Col>
            <Col
              lg={4}
              md={12}
              className="py-3  border-end-0 border-lg-end border-bottom border-lg-bottom-0"
            >
              <EventCard
                loading={loadingEvents}
                event={'Click To Open Rate'}
                rate={eventData?.data?.rate?.clickToOpenRate}
                comparisonRate={
                  eventData?.comparisonData?.rate?.clickToOpenRate
                }
              />
            </Col>
            <Col lg={4} md={12} className="py-3">
              <EventCard
                loading={loadingEvents}
                event={'Engagement Rate'}
                rate={eventData?.data?.rate?.engagementRate}
                comparisonRate={eventData?.comparisonData?.rate?.engagementRate}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <EventRates className={'shadow-none'} campaignId={campaign.id} />
    </div>
  );
};

Statistics.propTypes = {
  loading: PropTypes.boolean,
  campaign: PropTypes.object,
  segments: PropTypes.array
};

export default Statistics;
