import React, { useEffect, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  selectOrganizations,
  setOrganization,
  selectIsLoading,
  logout
} from 'redux/reducer/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import defaultAvatar from 'assets/img/avatar/default.png';
import './styles.scss';

const SelectOrganization = () => {
  const navigate = useNavigate();
  const isLoading = useAppSelector(selectIsLoading);
  const organizations = useAppSelector(selectOrganizations);
  const dispatch = useAppDispatch();

  const setOrg = async (userId, organizationId) => {
    try {
      const result = await dispatch(
        setOrganization({ userId, organizationId })
      );
      navigate('/');
      toast.success(`Logged in as ${result.payload.organization.name}`, {
        theme: 'colored'
      });
    } catch (error) {
      console.error('Error setting organization:', error);
      toast.error('Failed to select organization', { theme: 'colored' });
    }
  };

  const [sortedOrganizations, setSortedOrganizations] = useState([]);

  useEffect(() => {
    if (organizations) {
      const sortedOrgs = [...organizations].sort((a, b) => {
        if (a.organizationRoles[0].userId === a.ownerId) return -1;
        if (b.organizationRoles[0].userId === b.ownerId) return 1;

        return a.organizationRoles[0].role.localeCompare(
          b.organizationRoles[0].role
        );
      });
      setSortedOrganizations(sortedOrgs);
    }
  }, [organizations]);

  if (isLoading) {
    return (
      <Row className="justify-content-center mb-3 g-3">
        <Col lg={4} className="d-flex justify-content-center">
          <Lottie
            animationData={infiniteLoop}
            loop={true}
            style={{ height: '120px', width: '120px' }}
          />
        </Col>
        <h3 className="text-center">Loading...</h3>
      </Row>
    );
  }

  return (
    <>
      <Col>
        <Row
          className="g-5 justify-content-center mx-auto "
          style={{ maxWidth: '80%' }}
        >
          {sortedOrganizations?.map(org => (
            <Col
              key={org.id}
              xs={12}
              sm={6}
              md={4}
              xxl={3}
              className="d-flex justify-content-center"
            >
              <Card
                className="text-center card-hover"
                onClick={() => {
                  setOrg(
                    org.organizationRoles[0].userId,
                    org.organizationRoles[0].organizationId
                  );
                }}
                style={{
                  width: '250px',
                  margin: 'auto',
                  borderRadius: '10px',
                  cursor: 'pointer'
                }}
              >
                <Card.Body>
                  <Card.Text className="mb-3 fs-11">
                    {org.organizationRoles[0].userId === org.ownerId
                      ? 'Owner'
                      : org.organizationRoles[0].role}
                  </Card.Text>
                  <Card.Img
                    variant="top"
                    src={org.avatar || defaultAvatar}
                    className="rounded-circle mb-3"
                    style={{
                      width: '100px',
                      height: '100px',
                      objectFit: 'cover'
                    }}
                  />
                  <Card.Text>{org.name}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Link
          to="/login"
          className="d-flex mx-auto mt-4 text-center fs-10"
          onClick={() => {
            dispatch(logout());
          }}
          style={{ width: 'fit-content' }}
        >
          Back to login
        </Link>
      </Col>
    </>
  );
};

export default SelectOrganization;
