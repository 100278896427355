import {
  faFileUpload,
  faUserEdit,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const contactEventsIcon = {
  Open: {
    icon: 'envelope-open',
    bg: '#fde6d8',
    iconColor: 'text-warning'
  },
  Sent: {
    icon: 'paper-plane',
    bg: '#d9e7fa',
    iconColor: 'text-primary'
  },
  Delivered: {
    icon: 'check-circle',
    bg: '#d9f8eb',
    iconColor: 'text-success'
  },
  Bounce: {
    icon: 'times-circle',
    bg: '#e6e8ec',
    iconColor: 'text-secondary'
  },
  Complaint: {
    icon: 'exclamation-circle',
    bg: '#fbdbe1',
    iconColor: 'text-danger'
  },
  Click: {
    bg: '#d8f3ff',
    iconColor: 'text-info'
  }
};

export const emailContactEvents = [
  'Send',
  'Delivery',
  'Open',
  'Click',
  'Bounce',
  'Complaint'
];

export const emailEvents = [
  'Send',
  'Delivery',
  'Open',
  'Click',
  'Bounce',
  'Complaint',
  'Opt out'
];

export const operators = {
  '=': 'Equal',
  '!=': 'Not equal',
  '>': 'Greater than',
  '<': 'Less than',
  '>=': 'Greater than or equal',
  '<=': 'Less than or equal',
  contains: 'Contains'
};

export const dateOperators = {
  today: 'Today',
  last7Days: 'Last 7 days',
  last30Days: 'Last 30 days',
  yearToDate: 'Year to date',
  between: 'Between',
  specify: 'Specify the days'
};

export const scheduleTypes = ['hourly', 'daily', 'weekly', 'monthly'];

export const frequency = ['hourly', 'daily', 'weekly', 'monthly'];

export const communicationTypes = ['Email', 'Text'];

export const campaignTypes = ['One-off', 'Drip', 'Recurring'];

export const CampaignType = {
  ONEOFF: 'One-off',
  RECURRING: 'Recurring',
  DRIP: 'Drip'
};

export const campaignObjective = [
  'Promotional',
  'Servicing',
  'Event',
  'Re-engagement',
  'Feedback',
  'Product Launch and Announcement',
  'Dynamic'
];

export const communicationChannels = ['Email', 'SMS'];

export const RecurrencePattern = {
  HOURLY: 'hourly',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  YEARLY: 'yearly'
};

export const ExecutionType = {
  MANUAL: 'manual',
  AUTOMATIC: 'automatic'
};

export const RequestStatus = {
  APPROVED: 'Approved',
  DISAPPROVED: 'Disapproved'
};

export const UserStatusType = {
  ACTIVE: 'active',
  DISABLED: 'disabled'
};

export const OrganizationStatusType = {
  ACTIVE: 'active',
  DISABLED: 'disabled'
};

export const ProductionAccessStatusType = {
  ACTIVE: 'active',
  INPROGRESS: 'in progress'
};

export const EmailSendingStatus = {
  ACTIVE: 'Active',
  SUSPENDED: 'Suspended',
  WARNED: 'Warned',
  PRE_WARNED: 'Pre-warned'
};

export const emailType = ['Marketing', 'Transactional'];

export const contactPeriodType = {
  last7Days: 'Last 7 days',
  last30Days: 'Last 30 days',
  yearToDate: 'Year to date'
};

export const periodType = {
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
  YEAR: 'Year',
  LAST_7_DAYS: 'Last 7 days',
  LAST_30_DAYS: 'Last 30 days',
  ALL_TIME: 'All time'
};

export const OrganizationRole = {
  ADMIN: 'Admin',
  USER: 'User'
};

export const contactSourcesIcon = [
  {
    name: 'File upload',
    icon: faFileUpload,
    color: 'primary'
  },
  {
    name: 'Manual',
    icon: faUserEdit,
    color: 'info'
  },
  {
    name: 'Contact form',
    icon: faEnvelope,
    color: 'warning'
  }
];

export const contactSourcesVariant = [
  {
    name: 'File upload',
    variant: 'info'
  },
  {
    name: 'Manual',
    variant: 'info-subtle'
  },
  {
    name: 'Contact form',
    variant: 'progress-gradient'
  }
];
