import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
  Card,
  Collapse
} from 'react-bootstrap';
import WizardInput from 'components/wizard/WizardInput';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import { useForm } from 'react-hook-form';
import Divider from 'components/common/Divider';
import CustomFieldForm from 'components/contacts/CustomFieldForm';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import axiosClient from 'services/axios';

const ContactDetail = ({ contact, setContact }) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [updatingContact, setUpdatingContact] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const convertCustomFieldsToObject = fieldsArray => {
    const result = {};
    fieldsArray.forEach(field => {
      result[field.fieldName] = field.value;
    });
    return result;
  };

  const handleUpdateContact = () => {
    handleSubmit(async () => {
      setIsLoading(true);
      try {
        await axiosClient.put(
          `/contacts/${updatingContact.id}`,
          updatingContact
        );
        toast.success('Contact updated successfully');
        setContact({
          ...updatingContact,
          customFields: convertCustomFieldsToObject(
            updatingContact.customFields
          )
        });
        setIsLoading(false);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Failed to update updatingContact');
        }
        setIsLoading(false);
      }
    })();
  };

  const fetchCustomFields = async () => {
    setIsLoading(true);
    try {
      const response = await axiosClient.get(`/custom-fields`);
      setCustomFields(response.data.customFields);
      setIsLoading(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get custom fields');
      }
      setIsLoading(false);
    }
  };

  const handleUpdateContactToArr = (_updatingContact = {}) => {
    const _customFields = [];
    if (_updatingContact.customFields) {
      Object.keys(_updatingContact.customFields).forEach(field => {
        const customField = customFields.find(
          item => item.fieldName.toLowerCase() === field.toLowerCase()
        );

        if (customField) {
          _customFields.push({
            fieldId: customField.id,
            fieldName: customField.fieldName,
            fieldType: customField.fieldType,
            value: _updatingContact.customFields[field]
          });
        }
      });
    }

    setUpdatingContact({
      ..._updatingContact,
      customFields: _customFields
    });
  };

  const handleCancel = () => {
    if (contact) {
      handleUpdateContactToArr(contact);
    }
    setIsEdit(false);
  };

  useEffect(() => {
    fetchCustomFields();
  }, []);

  useEffect(() => {
    reset();
    if (contact && customFields) {
      handleUpdateContactToArr(contact);
    }
  }, [contact, customFields]);

  useEffect(() => {
    if (updatingContact) {
      reset(updatingContact);
    }
  }, [updatingContact, reset]);

  return (
    <Card className="mb-3">
      <Card.Header className="bg-body-muted d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Detail</h5>
        {isEdit ? (
          <div className="d-flex">
            <Button
              variant="secondary"
              className="me-2"
              disabled={isLoading}
              onClick={() => {
                setIsEdit(false);
                handleCancel();
              }}
              style={{ width: 'fit-content' }}
            >
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              onClick={() => handleUpdateContact(false)}
              style={{ width: 'fit-content' }}
            >
              Save
            </Button>
          </div>
        ) : (
          <Button
            onClick={() => setIsEdit(true)}
            style={{ width: 'fit-content' }}
            hidden={isEdit}
          >
            Edit
          </Button>
        )}
      </Card.Header>

      <Card.Body className="text-1000">
        {isLoading || !updatingContact ? (
          <Row className="justify-content-center mb-3 g-3">
            <Col lg={4} className="d-flex justify-content-center">
              <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
            <h3 className="text-center">Loading...</h3>
          </Row>
        ) : (
          <>
            <Form onSubmit={handleUpdateContact}>
              <Row>
                <WizardInput
                  label="First name"
                  name="firstName"
                  errors={errors}
                  formGroupProps={{ className: 'col-6 mb-3' }}
                  formControlProps={{
                    ...register('firstName', {
                      required: 'First name is required'
                    }),
                    onChange: e => {
                      setUpdatingContact({
                        ...updatingContact,
                        firstName: e.target.value
                      });
                    },
                    value: updatingContact.firstName,
                    disabled: !isEdit
                  }}
                />
                <WizardInput
                  label="Last name"
                  name="lastName"
                  errors={errors}
                  formGroupProps={{ className: 'col-6 mb-3' }}
                  formControlProps={{
                    ...register('lastName', {
                      required: 'Last name is required'
                    }),
                    onChange: e => {
                      setUpdatingContact({
                        ...updatingContact,
                        lastName: e.target.value
                      });
                    },
                    value: updatingContact.lastName,
                    disabled: !isEdit
                  }}
                />
              </Row>

              <WizardInput
                type="email"
                errors={errors}
                label="Email*"
                name="email"
                formGroupProps={{ className: 'mb-3' }}
                formControlProps={{
                  ...register('email', {
                    required: 'Email field is required',
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: 'Email must be valid'
                    }
                  }),
                  onChange: e => {
                    setUpdatingContact({
                      ...updatingContact,
                      email: e.target.value
                    });
                  },
                  value: updatingContact.email,
                  disabled: !isEdit
                }}
              />
              <WizardInput
                type="text"
                label="Phone number"
                name="phoneNumber"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('phoneNumber', {
                    required: 'Phone number is required'
                  }),
                  onChange: e => {
                    setUpdatingContact({
                      ...updatingContact,
                      phoneNumber: e.target.value
                    });
                  },
                  value: updatingContact.phoneNumber,
                  disabled: !isEdit
                }}
              />

              <OverlayTrigger
                placement="top"
                overlay={
                  updatingContact.optOutEvents.find(
                    item => item.communicationType === 'Text'
                  ) ? (
                    <Tooltip id="text-opt-out-tooltip">
                      This option is disabled because the updatingContact has
                      opted out of text communications.
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <div style={{ width: 'fit-content' }}>
                  <Form.Check type="checkbox" id="textOptIn" className="mb-0">
                    <Form.Check.Input
                      type="checkbox"
                      name="textOptIn"
                      checked={updatingContact?.textOptIn}
                      onChange={e =>
                        setUpdatingContact({
                          ...updatingContact,
                          textOptIn: e.target.checked
                        })
                      }
                      disabled={
                        updatingContact.optOutEvents.find(
                          item => item.communicationType === 'Text'
                        ) || !isEdit
                      }
                    />
                    <Form.Check.Label className="mb-0 text-700">
                      Text Opt-in
                    </Form.Check.Label>
                  </Form.Check>
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  updatingContact.optOutEvents.find(
                    item => item.communicationType === 'Email'
                  ) ? (
                    <Tooltip id="email-opt-out-tooltip">
                      This option is disabled because the updatingContact has
                      opted out of email communications, email has bounced or
                      email reported as spam.
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <div style={{ width: 'fit-content' }}>
                  <Form.Check type="checkbox" id="emailOptIn" className="mb-0">
                    <Form.Check.Input
                      type="checkbox"
                      name="emailOptIn"
                      checked={updatingContact.emailOptIn}
                      onChange={e =>
                        setUpdatingContact({
                          ...updatingContact,
                          emailOptIn: e.target.checked
                        })
                      }
                      disabled={
                        updatingContact.optOutEvents.find(
                          item => item.communicationType === 'Email'
                        ) || !isEdit
                      }
                    />
                    <Form.Check.Label className="mb-0 text-700">
                      Email Opt-in
                    </Form.Check.Label>
                  </Form.Check>
                </div>
              </OverlayTrigger>
              <Collapse in={collapsed}>
                <div>
                  <Divider></Divider>
                  <h5 className="my-3">Custom field</h5>
                  <CustomFieldForm
                    customFields={customFields}
                    contact={updatingContact}
                    setContact={setUpdatingContact}
                    isDisable={!isEdit}
                  />
                </div>
              </Collapse>
            </Form>
          </>
        )}
      </Card.Body>

      <Card.Footer className="bg-body-muted p-0 border-top d-grid">
        <Button variant="link" onClick={() => setCollapsed(!collapsed)}>
          Show {collapsed ? 'less' : 'more'}
          <FontAwesomeIcon
            icon="chevron-down"
            className="ms-2 fs-11"
            transform={collapsed ? 'rotate-180' : ''}
          />
        </Button>
      </Card.Footer>
    </Card>
  );
};

ContactDetail.propTypes = {
  contact: PropTypes.object,
  setContact: PropTypes.func
};

export default ContactDetail;
