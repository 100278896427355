import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Modal,
  OverlayTrigger,
  Placeholder,
  Row,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import axiosClient from 'services/axios';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/store';
import { selectUser } from 'redux/reducer/auth';
import UpdateCampaignModal from 'components/campaigns/UpdateCampaignModal';
import { CampaignType, ExecutionType } from 'data/common';
import AddCampaignModal from 'components/campaigns/AddCampaignModal';

const ScheduleColors = ['primary', 'secondary', 'success', 'danger', 'warning'];

const CampaignManagement = () => {
  const {
    config: { isRTL }
  } = useAppContext();
  const calendarRef = useRef();
  const [title, setTitle] = useState('');
  const [day, setDay] = useState('');
  const [calendarApi, setCalendarApi] = useState({});

  const user = useAppSelector(selectUser);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [month, setMonth] = useState(dayjs().format('YYYY-MM'));
  const [next5Days, setNext5Days] = useState([]);

  const [updatingCampaign, setUpdatingCampaign] = useState(null);
  const [showUpdateCampaignModal, setShowUpdateCampaignModal] = useState(false);
  const [showAddCampaignModal, setShowAddCampaignModal] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [showedCampaigns, setShowedCampaigns] = useState([]);
  const [showCampaignModal, setShowCampaignModal] = useState(false);

  const [segments, setSegments] = useState([]);
  const [groups, setGroups] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [emailSenders, setEmailSenders] = useState([]);
  const [textSenders, setTextSenders] = useState([]);

  const handleShowCampaign = campaignId => {
    // setModalEventContent({ event: calendarApi.getEventById(eventsInfo.id) });
    // setIsOpenEventModal(true);
    fetchCampaign(campaignId);
  };

  const onDateClick = info => {
    setSelectedDate(info.dateStr);
    const executions = data.filter(
      item =>
        dayjs(item.scheduledStartDate).format('YYYY-MM-DD') === info.dateStr
    );

    if (executions.length > 0) {
      const campaigns = executions.reduce((acc, execution) => {
        const index = acc.findIndex(item => item.id === execution.campaigns.id);
        if (index === -1) {
          acc.push({
            ...execution.campaigns,
            campaignExecutions: [execution]
          });
        } else {
          acc[index].campaignExecutions.push(execution);
        }
        return acc;
      }, []);

      setShowCampaignModal(true);
      setShowedCampaigns(campaigns);
    } else {
      setShowAddCampaignModal(true);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get(
        `/campaign-executions?month=${month}`
      );
      setData(response.data.executions);
      setNext5Days(response.data.next5DaysExecutions);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };
  console.log(showedCampaigns);

  const fetchCampaign = async id => {
    try {
      const response = await axiosClient.get(`/campaigns/${id}`);
      handleUpdateCampaign(response.data);
      setShowUpdateCampaignModal(true);
    } catch (error) {
      console.error(error);
      toast.error('Failed to fetch campaign');
    }
  };

  const handleUpdateCampaign = _updatingCampaign => {
    let emailSender = '';
    let textSender = '';
    _updatingCampaign.campaignExecutions.forEach(execution => {
      execution.campaignCommunications.forEach(communication => {
        if (emailSender && textSender) return;
        const emailAccount = emailSenders.find(
          sender => sender.id === communication.senderId
        );
        if (communication.communicationType === 'Email' && emailAccount) {
          emailSender = communication.senderId;
        } else if (communication.communicationType === 'Text') {
          textSender = communication.senderId;
        }
      });
    });

    // Sort the campaignExecutions by scheduledStartDate
    const sortedExecutions = _updatingCampaign.campaignExecutions.sort((a, b) =>
      dayjs(a.scheduledStartDate).isBefore(dayjs(b.scheduledStartDate)) ? -1 : 1
    );

    if (
      _updatingCampaign.campaignType === CampaignType.RECURRING &&
      _updatingCampaign.campaignExecutions.length > 0
    ) {
      const executions = [];
      sortedExecutions.forEach(execution => {
        if (
          !executions.find(
            item =>
              item.endDate === execution.endDate &&
              item.frequency === execution.frequency &&
              JSON.stringify(item.segmentIds) ===
                JSON.stringify(execution.segmentIds) &&
              JSON.stringify(item.campaignCommunications) ===
                JSON.stringify(execution.campaignCommunications)
          )
        ) {
          executions.push(execution);
        }
      });
      _updatingCampaign.campaignExecutions = [...executions];
    }

    setUpdatingCampaign({
      ..._updatingCampaign,
      campaignExecutions: _updatingCampaign.campaignExecutions.map(
        (execution, index) => ({
          ...execution,
          index,
          type:
            _updatingCampaign.campaignType !== 'One-off'
              ? ExecutionType.MANUAL
              : ExecutionType.AUTOMATIC,
          scheduledStartDate: dayjs(execution.scheduledStartDate).format(
            'MM/DD/YYYY HH:mm'
          ),
          campaignCommunications: ['Email', 'Text'].map(type => {
            const communication = execution.campaignCommunications.find(
              item => item.communicationType === type
            );
            if (!communication) {
              const campaignCommunication = {
                templateId: '',
                senderId: '',
                communicationType: type,
                title: '',
                enabled: false
              };
              if (
                _updatingCampaign.status === 'draft' &&
                _updatingCampaign.communicationTypes.find(item => item === type)
              ) {
                campaignCommunication.enabled = true;
              }

              return campaignCommunication;
            }
            return {
              ...communication,
              enabled: communication.templateId !== ''
            };
          })
        })
      ),

      scheduledStartDate: dayjs(new Date()).format('MM/DD/YYYY HH:mm'),
      numberOfExecutions: null,
      recurrencePattern: '',
      emailSender: emailSender,
      textSender: textSender
    });
    setShowUpdateCampaignModal(true);
  };

  useEffect(() => {
    setCalendarApi(calendarRef.current.getApi());
  }, []);

  useEffect(() => {
    fetchData();
  }, [month]);

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const response = await axiosClient.get('/customer-segments');
        setSegments(response.data.customerSegments);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Error to get segments');
        }
      }
    };

    const fetchGroups = async () => {
      try {
        const response = await axiosClient.get('/groups');
        setGroups(response.data.groups);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Error to get groups');
        }
      }
    };

    const fetchContacts = async () => {
      try {
        const response = await axiosClient.get('/contacts');
        setContacts(response.data.contacts);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Error to get contacts');
        }
      }
    };

    const fetchTemplates = async () => {
      try {
        const response = await axiosClient.get(`/templates`);
        setTemplates(response.data);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || 'Error fetching templates';
        toast.error(errorMessage);
      }
    };

    const fetchSenders = async () => {
      try {
        const [emailSenderResponse, textSenderResponse] = await Promise.all([
          axiosClient.get('/email-account/senders'),
          axiosClient.get('/phone-numbers')
        ]);
        setEmailSenders(emailSenderResponse.data);
        setTextSenders(textSenderResponse.data);
      } catch (error) {
        console.error(error);
        toast.error('Failed to fetch senders');
      }
    };
    if (user) {
      fetchSegments();
      fetchGroups();
      fetchContacts();
      fetchTemplates();
      fetchSenders();
    }
  }, [user]);

  const getDate = () => {
    return calendarApi.getCurrentData().currentDate.toLocaleString('en-us', {
      weekday: 'long'
    });
  };

  return (
    <>
      <Card className="overflow-hidden h-100">
        <Card.Body className="p-0 management-calendar">
          <Row className="g-3">
            <Col md={7}>
              <div className="p-x1">
                <Flex justifyContent="between">
                  <div className="order-md-1">
                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                          Previous
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="falcon-default"
                        size="sm"
                        className="me-1"
                        onClick={() => {
                          calendarApi.prev();
                          setTitle(calendarApi.getCurrentData().viewTitle);
                          setDay(getDate);
                          setMonth(
                            dayjs(
                              calendarApi.getCurrentData().currentDate
                            ).format('YYYY-MM')
                          );
                        }}
                      >
                        <FontAwesomeIcon icon="chevron-left" />
                      </Button>
                    </OverlayTrigger>
                    <Button
                      size="sm"
                      variant="falcon-default"
                      onClick={() => {
                        calendarApi.today();
                        setTitle(calendarApi.getCurrentData().viewTitle);
                        setDay(getDate);
                      }}
                      className="px-sm-4"
                    >
                      Today
                    </Button>
                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                          Next
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="falcon-default"
                        size="sm"
                        className="ms-1"
                        onClick={() => {
                          calendarApi.next();
                          setTitle(calendarApi.getCurrentData().viewTitle);
                          setDay(getDate);
                          setMonth(
                            dayjs(
                              calendarApi.getCurrentData().currentDate
                            ).format('YYYY-MM')
                          );
                        }}
                      >
                        <FontAwesomeIcon icon="chevron-right" />
                      </Button>
                    </OverlayTrigger>
                  </div>

                  <IconButton
                    variant="falcon-primary"
                    iconClassName="me-2"
                    icon="plus"
                    size="sm"
                    onClick={() => {
                      setShowAddCampaignModal(true);
                    }}
                  >
                    Schedule campaign
                  </IconButton>
                </Flex>
              </div>

              <Placeholder
                as="div"
                animation="glow"
                className={classNames({
                  'd-none': !loading
                })}
              >
                <Placeholder xs={12} style={{ width: '100%', height: 360 }} />
              </Placeholder>
              <div
                className={classNames('calendar-outline px-3', {
                  'd-none': loading
                })}
              >
                <FullCalendar
                  ref={calendarRef}
                  headerToolbar={false}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  themeSystem="bootstrap"
                  direction={isRTL ? 'rtl' : 'ltr'}
                  height={360}
                  dateClick={onDateClick}
                  events={data.map(item => ({
                    id: item.id,
                    title: item.campaigns.name,
                    start: dayjs(item.scheduledStartDate).format('YYYY-MM-DD'),
                    end: dayjs(item.scheduledStartDate).format('YYYY-MM-DD'),
                    display: 'background',
                    color: ScheduleColors[0],
                    classNames: `border border-2 border-primary bg-100`
                  }))}
                />
              </div>
            </Col>
            <Col md={5} className="bg-body-tertiary pt-3">
              <div className="px-3">
                <h4 className="mb-0 fs-9 fs-sm-8 fs-lg-7">
                  {title ||
                    `${calendarApi.currentDataManager?.data?.viewTitle}`}
                </h4>
                <p className="text-500 mb-0">
                  {day ||
                    `${new Date().toLocaleString('en-us', {
                      weekday: 'long'
                    })}`}
                </p>
                <ul
                  className="list-unstyled mt-3 scrollbar management-calendar-events"
                  id="management-calendar-events"
                >
                  {next5Days &&
                    next5Days.map(execution => (
                      <li
                        className="border-top pt-3 mb-3 pb-1 cursor-pointer"
                        onClick={() =>
                          handleShowCampaign(execution.campaigns.id)
                        }
                        key={execution.id}
                      >
                        <div
                          // className={`border-start border-3 ps-3 mt-1 border-${ScheduleColors[index]}`}
                          className={`mt-1 `}
                        >
                          <h6 className="mb-1 fw-semibold text-700">
                            {execution.campaigns.name}
                          </h6>
                          <p className="fs-11 text-600 mb-0">
                            {execution.scheduledStartDate &&
                              dayjs(execution.scheduledStartDate).format(
                                'MM/DD/YYYY HH:mm'
                              )}
                          </p>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal
        show={showCampaignModal}
        onHide={() => {
          setShowCampaignModal(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Campaigns executed on {selectedDate}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          <div className="overflow-y-auto" style={{ height: 300 }}>
            <ul
              className="list-unstyled scrollbar mb-0 management-calendar-events rounded"
              id="management-calendar-events"
            >
              {showedCampaigns &&
                showedCampaigns.map(campaign => {
                  const startDate = dayjs(
                    campaign.campaignExecutions[0].scheduledStartDate
                  )?.format('MM/DD/YYYY HH:mm');
                  const endDate = dayjs(
                    campaign.campaignExecutions[
                      campaign.campaignExecutions.length - 1
                    ].scheduledStartDate
                  )?.format('MM/DD/YYYY HH:mm');
                  return (
                    <li
                      className="border-bottom py-2 cursor-pointer"
                      onClick={() => {
                        handleShowCampaign(campaign.id);
                        setShowCampaignModal(false);
                      }}
                      key={campaign.id}
                    >
                      <div
                        // className={`border-start border-3 ps-3 mt-1 border-${ScheduleColors[index]}`}
                        className={`ms-4 mt-1 `}
                      >
                        <h6 className="mb-1 fw-semibold text-700">
                          {campaign.name}
                        </h6>
                        <p className="fs-11 text-600 mb-0">
                          {startDate}
                          {endDate !== startDate ? ' - ' : ''}
                          {endDate !== startDate && endDate}
                        </p>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      <AddCampaignModal
        showAddCampaignModal={showAddCampaignModal}
        setShowAddCampaignModal={setShowAddCampaignModal}
        fetchCampaigns={fetchData}
        emailSenders={emailSenders}
        textSenders={textSenders}
        templates={templates}
        segments={segments}
        groups={groups}
        contacts={contacts}
      />

      {updatingCampaign && (
        <UpdateCampaignModal
          showUpdateCampaignModal={showUpdateCampaignModal}
          setShowUpdateCampaignModal={setShowUpdateCampaignModal}
          fetchCampaigns={fetchData}
          updatingCampaign={updatingCampaign}
          emailSenders={emailSenders}
          textSenders={textSenders}
          templates={templates}
          segments={segments}
          groups={groups}
          contacts={contacts}
          isViewingCampaign={false}
        />
      )}
    </>
  );
};
CampaignManagement.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      color: PropTypes.string,
      classNames: PropTypes.string
    })
  )
};

export default CampaignManagement;
