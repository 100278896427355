import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useForm, Controller, useWatch } from 'react-hook-form';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import Divider from 'components/common/Divider';
import WizardInput from 'components/wizard/WizardInput';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import { GoCheckCircle } from 'react-icons/go';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import dayjs from 'dayjs';

const GroupModal = ({
  showGroupModal,
  setShowGroupModal,
  contacts,
  fetchGroups,
  groupDetail,
  mode
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      contactIds: []
    }
  });

  const contactIdsValue = useWatch({
    control,
    name: `contactIds`
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    reset();
  }, [showGroupModal]);

  const handleAddSingleGroup = async data => {
    setLoading(true);
    try {
      await axiosClient.post('/groups', data);
      fetchGroups();
      toast.success('Group added successfully');
      setShowGroupModal(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Group add failed');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateGroup = async data => {
    setLoading(true);
    try {
      await axiosClient.put(`/groups/${groupDetail.id}`, data);
      fetchGroups();
      toast.success('Group updated successfully');
      setShowGroupModal(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Group update failed');
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = data => {
    if (mode === 'VIEW') return;
    if (mode === 'EDIT') {
      handleUpdateGroup(data);
    } else {
      handleAddSingleGroup(data);
    }
  };

  useEffect(() => {
    if (groupDetail && mode !== 'ADD') {
      const transformData = {
        ...groupDetail
      };

      Object.keys(transformData).forEach(item => {
        setValue(item, transformData[item]);
      });
    }
  }, [groupDetail]);

  return (
    <Modal
      show={showGroupModal}
      onHide={() => {
        setShowGroupModal(false);
      }}
      size="xl"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {mode === 'ADD'
            ? 'Add Group'
            : mode === 'EDIT'
            ? 'Update Group'
            : 'View Group'}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {loading ? (
          <Row className="justify-content-center mb-3 g-3">
            <Col lg={4} className="d-flex justify-content-center">
              <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
            <h3 className="text-center">Loading...</h3>
          </Row>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <WizardInput
                label="Name"
                name="name"
                errors={errors}
                formGroupProps={{ className: 'col-12 mb-3' }}
                formControlProps={{
                  ...register('name', {
                    required: 'Name is required'
                  }),
                  onChange: e => {
                    setValue('name', e.target.value);
                  },
                  disabled: mode === 'VIEW'
                }}
              />
            </Row>
            <Row>
              <div className="mb-3">
                <Form.Label>Contacts</Form.Label>
                <Controller
                  name="contactIds"
                  control={control}
                  rules={{ required: 'Please select contact' }}
                  defaultValue={contactIdsValue}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={contacts?.map(item => ({
                        value: item.id,
                        label: `${item.firstName} ${item.lastName}`
                      }))}
                      placeholder="Select contact"
                      isMulti
                      isSearchable={true}
                      classNamePrefix="react-select"
                      name="contactIds"
                      value={contactIdsValue?.map(item => ({
                        value: item,
                        label: `${
                          contacts?.find(contact => contact.id === item)
                            ?.firstName || ''
                        } ${
                          contacts?.find(contact => contact.id === item)
                            ?.lastName || ''
                        }`
                      }))}
                      onChange={data => {
                        setValue(
                          'contactIds',
                          data.map(item => item.value)
                        );
                      }}
                      isDisabled={mode === 'VIEW'}
                    />
                  )}
                />
                {errors['contactIds'] && (
                  <div className="invalid-feedback d-block">
                    {errors['contactIds'].message}
                  </div>
                )}

                <div
                  style={{ overflowX: 'auto', maxHeight: 500 }}
                  className="mt-3 segment-table"
                >
                  <Table responsive>
                    {contactIdsValue.length > 0 && (
                      <thead>
                        <tr className="border-2">
                          <th scope="col" width="80">
                            First name
                          </th>
                          <th scope="col" width="80">
                            Last name
                          </th>
                          <th scope="col" width="300">
                            Email
                          </th>
                          <th scope="col" width="120">
                            Phone number
                          </th>
                          <th scope="col" width="80">
                            Source
                          </th>
                          <th scope="col" width="70" className="text-center">
                            Text Opt-in
                          </th>
                          <th scope="col" width="70" className="text-center">
                            Email Opt-in
                          </th>
                          <th scope="col" width="80">
                            Last contacted date
                          </th>
                          <th scope="col" width="80">
                            Contact lost date
                          </th>
                          <th scope="col" width="170">
                            Custom fields
                          </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {contactIdsValue.length > 0 &&
                        contacts
                          .filter(item => contactIdsValue.includes(item.id))
                          .map((contact, index) => (
                            <tr key={`contact-${index}`}>
                              <td width="80">{contact.firstName}</td>
                              <td width="80">{contact.lastName}</td>
                              <td width="300">{contact.email}</td>
                              <td width="120">{contact.phoneNumber}</td>
                              <td width="80">{contact.source}</td>
                              <td width="70" className="text-center">
                                {contact.textOptIn ? (
                                  <GoCheckCircle className="text-success fs-9" />
                                ) : (
                                  <IoIosCloseCircleOutline className="text-danger fs-8" />
                                )}
                              </td>
                              <td width="70" className="text-center">
                                {contact.emailOptIn ? (
                                  <GoCheckCircle className="text-success fs-9 " />
                                ) : (
                                  <IoIosCloseCircleOutline className="text-danger fs-8" />
                                )}
                              </td>
                              <td width="80">
                                {dayjs(contact.lastContactedDate).format(
                                  'MM/DD/YYYY HH:mm'
                                )}
                              </td>
                              <td width="80">
                                {contact.contactLostDate &&
                                  dayjs(contact.contactLostDate).format(
                                    'MM/DD/YYYY HH:mm'
                                  )}
                              </td>
                              <td width="170" className="text-capitalize">
                                {contact.customFields &&
                                  Object.keys(contact.customFields).map(
                                    (field, index) => (
                                      <div key={index} className="d-flex gap-2">
                                        <span className="text-capitalize">
                                          {field.replaceAll('_', ' ')}:
                                        </span>

                                        <i>
                                          {contact.customFields[
                                            field
                                          ].toString()}
                                        </i>
                                      </div>
                                    )
                                  )}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Row>
          </Form>
        )}
      </Modal.Body>
      <Divider />
      <div className="col-12 d-flex flex-column gap-3 justify-content-center align-items-center px-4 mb-3">
        {mode === 'VIEW' ? (
          <Button
            variant="secondary"
            onClick={() => {
              setShowGroupModal(false);
            }}
          >
            Close
          </Button>
        ) : (
          <Button
            disabled={loading}
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        )}
      </div>
    </Modal>
  );
};

GroupModal.propTypes = {
  showGroupModal: PropTypes.bool,
  setShowGroupModal: PropTypes.func,
  contacts: PropTypes.array,
  fetchGroups: PropTypes.func,
  groupDetail: PropTypes.object,
  mode: PropTypes.oneOf(['VIEW', 'ADD', 'EDIT'])
};

export default GroupModal;
