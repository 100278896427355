import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import ContactCard from 'components/dashboard/ContactCard';

import LocationBySession from 'components/dashboards/marketing/LocationBySession/LocationBySession';
import AvgCallDuration from 'components/dashboards/marketing/avg-call-duration/AvgCallDuration';
import LeadConversation from 'components/dashboards/marketing/lead-conversation/LeadConversation';
import ToDoList from 'components/dashboards/marketing/ToDoList';
import RecentLeads from 'components/dashboards/marketing/recent-leads/RecentLeads';
import Greetings from 'components/dashboards/marketing/greetings/Greetings';
import { intelligence } from 'data/dashboard/analytics';
import ProjectStatistics from 'components/dashboards/marketing/project-statistics/ProjectStatistics';
import {
  projectsTableKTN,
  runningProjects,
  progressBarKTN
} from 'data/dashboard/projectManagement';
import SpaceWarning from 'components/dashboards/marketing/SpaceWarning';
import RunningProjects from 'components/dashboards/marketing/RunningProjects';
import DealStorageFunnel from 'components/dashboards/crm/deal-storage-funnel/DealStorageFunnel';
import Intelligence from 'components/dashboards/analytics/Intelligence';
import { contactPeriodType } from 'data/common';
import ContactSourcesBar from 'components/dashboard/ContactSourcesBar';
import axiosClient from 'services/axios';
import EventRates from 'components/dashboard/events/EventRates';
import EventCard from 'components/dashboard/events/EventCard';
import CampaignManagement from 'components/dashboard/CampaignManagement';

const MainAccountDashboard = () => {
  const [period, setPeriod] = useState(contactPeriodType.last7Days);
  const [eventData, setEventData] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [eventPeriod, setEventPeriod] = useState(contactPeriodType.last7Days);

  const fetchEventStats = async () => {
    setLoadingEvents(true);
    try {
      const formattedPeriod = eventPeriod.replace(/\s+/g, '_').toLowerCase();
      const response = await axiosClient.get(
        `/campaign-events/analyze?period=${formattedPeriod}`
      );
      setEventData(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoadingEvents(false);
  };

  useEffect(() => {
    fetchEventStats();
  }, [eventPeriod]);

  return (
    <>
      <Greetings setPeriod={setPeriod} />
      <Row className="g-3 mb-3">
        <Col xxl={12}>
          <ContactCard period={period} />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={8}>
          <ContactSourcesBar />
        </Col>
        <Col lg={4}>
          <Card className="h-100">
            <Card.Header
              className="d-flex justify-content-between align-items-center"
              style={{ padding: '10px 20px' }}
            >
              <h6 className="mb-0">Events</h6>
              <Form.Select
                size="sm"
                className="me-2"
                onChange={e => {
                  setEventPeriod(e.target.value);
                }}
                style={{ width: 'fit-content' }}
              >
                <option>{contactPeriodType.last7Days}</option>
                <option>{contactPeriodType.last30Days}</option>
                <option>{contactPeriodType.yearToDate}</option>
              </Form.Select>
            </Card.Header>
            <Card.Body className="py-0">
              <div className="py-3 border-bottom">
                <EventCard
                  loading={loadingEvents}
                  event={'Click Rate'}
                  rate={eventData?.data?.rate?.clickRate}
                  comparisonRate={eventData?.comparisonData?.rate?.clickRate}
                  size={'large'}
                />
              </div>
              <div className="py-3 border-bottom">
                <EventCard
                  loading={loadingEvents}
                  event={'Click To Open Rate'}
                  rate={eventData?.data?.rate?.clickToOpenRate}
                  comparisonRate={
                    eventData?.comparisonData?.rate?.clickToOpenRate
                  }
                  size={'large'}
                />
              </div>
              <div className="py-3">
                <EventCard
                  loading={loadingEvents}
                  event={'Engagement Rate'}
                  rate={eventData?.data?.rate?.engagementRate}
                  comparisonRate={
                    eventData?.comparisonData?.rate?.engagementRate
                  }
                  size={'large'}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="g-3 mb-3 align-items-stretch">
        <Col xxl={12}>
          <EventRates className="h-100" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={7}>
          <CampaignManagement />
        </Col>
        <Col lg={5}>
          <ProjectStatistics
            progressBar={progressBarKTN}
            projectsTable={projectsTableKTN}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-3 align-items-stretch">
        <Col md={6} xxl={5}>
          <Intelligence data={intelligence} className="h-100" />
        </Col>

        {/* This column becomes a flex container with gap-3 */}
        <Col xxl={7} md={6} className="d-flex flex-column gap-3">
          <DealStorageFunnel className="flex-fill" />
          <SpaceWarning className="flex-fill" />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={7}>
          <LocationBySession />
        </Col>
        <Col lg={5}>
          <Row className="g-3">
            <Col xs={12}>
              <AvgCallDuration />
            </Col>
            <Col xs={12}>
              <LeadConversation />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="g-3">
        <Col lg={4}>
          <RunningProjects data={runningProjects} />
        </Col>
        <Col lg={4}>
          <ToDoList />
        </Col>
        <Col lg={4}>
          <RecentLeads />
        </Col>
      </Row>
    </>
  );
};

export default MainAccountDashboard;
