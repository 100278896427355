import React, { useEffect, useState } from 'react';
import ContactBanner from 'components/contacts/Banner';
import { useParams } from 'react-router-dom';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';
import ContactDetail from 'components/contacts/ContactDetail';
import Metrics from 'components/contacts/Metrics';
import Associations from 'components/contacts/Associations';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import RecentActivity from 'components/contacts/RecentActivity';

const ViewContactsPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState();

  const fetchContact = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get(`/contacts/${id}`);
      setContact(response.data);
    } catch (error) {
      setLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to fetch template');
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      fetchContact();
    }
  }, [id]);

  return loading ? (
    <Row
      className="justify-content-center align-item-center mb-3 g-3"
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Col
        lg={4}
        className="d-flex justify-content-center"
        style={{ width: '100%' }}
      >
        <Lottie
          animationData={infiniteLoop}
          loop={true}
          style={{ height: '120px', width: '120px' }}
        />
      </Col>
      <h3 className="text-center">Loading...</h3>
    </Row>
  ) : (
    <>
      <ContactBanner contact={contact} />
      <ContactDetail contact={contact} setContact={setContact} />
      <Row
        className="mb-3 g-3  d-flex align-items-stretch"
        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
      >
        <Col className="d-flex" lg={7}>
          <Associations contact={contact} />
        </Col>
        <Col className="d-flex" lg={5}>
          <RecentActivity contact={contact} />
        </Col>
      </Row>
      <Metrics contact={contact} />
    </>
  );
};

export default ViewContactsPage;
