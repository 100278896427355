import React, { useEffect, useState } from 'react';
import { Card, Col, ProgressBar, Row, Placeholder } from 'react-bootstrap';
// import CardDropdown from 'components/common/CardDropdown';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { contactSourcesVariant } from 'data/common';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';

const ContactSourcesBar = () => {
  const [loading, setLoading] = useState(false);
  const [totalContacts, setTotalContacts] = useState([]);
  const [customData, setCustomData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const fetchContactSources = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get('contacts/sources');
      setTotalContacts(response.data.total);
      const mappedData = response.data['data'].map((item, index) => ({
        id: index,
        title: item.source,
        total: item.totalCount,
        variant: contactSourcesVariant.find(
          source => source.name === item.source
        )?.variant,
        amount: item.percentage
      }));

      setCustomData(mappedData);
      setFilteredData(mappedData.filter(item => item.total !== 0));
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to fetch contact stats');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContactSources();
  }, []);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h6 className="mb-0">Contact Sources</h6>
      </Card.Header>
      <Card.Body as={Flex} alignItems="center">
        <div className="w-100">
          {loading ? (
            <Placeholder className="mb-5" as="h3" animation="glow">
              <Placeholder xs={12} style={{ width: '50px', height: '30px' }} />
            </Placeholder>
          ) : (
            <h3 className="text-700 mb-6">{totalContacts}</h3>
          )}
          {loading ? (
            <Placeholder
              className="mt-xxl-auto"
              as="div"
              animation="glow"
              style={{ height: '20px' }}
            >
              <Placeholder xs={12} style={{ height: '20px' }} />
            </Placeholder>
          ) : (
            <ProgressBar
              className="font-sans-serif fs-10 fw-medium mt-xxl-auto rounded-4 overflow-visible"
              style={{ height: 20 }}
            >
              {filteredData.map((item, index) => (
                <ProgressBar
                  variant={item.variant}
                  now={item.amount}
                  key={item.id}
                  className={classNames('overflow-visible position-relative', {
                    'rounded-pill': filteredData.length === 1,
                    'rounded-end rounded-pill':
                      index === 0 && filteredData.length > 1,
                    'rounded-start rounded-pill':
                      index === filteredData.length - 1 &&
                      filteredData.length > 1,
                    'border-end border-100 border-2':
                      index !== filteredData.length - 1,
                    'rounded-0':
                      index !== filteredData.length - 1 && index !== 0
                  })}
                  label={
                    <span
                      className="text-700 position-absolute"
                      style={{ top: -30 }}
                    >
                      {item.total}
                    </span>
                  }
                />
              ))}
            </ProgressBar>
          )}
          {loading ? (
            <Placeholder
              className="mt-3"
              as="div"
              animation="glow"
              style={{ height: '20px' }}
              xxl={6}
              xs={6}
            >
              <Placeholder xs={12} style={{ height: '20px' }} />
            </Placeholder>
          ) : (
            <Row className="fs-10 fw-semibold text-500 mt-3 g-0">
              {customData.map((item, index) => (
                <Col
                  xs="auto"
                  key={index}
                  as={Flex}
                  alignItems="center"
                  className="pe-3"
                >
                  <span className={`dot bg-${item.variant}`}></span>
                  <span>{item.title}</span>
                  <span className="d-none d-md-inline-block d-xxl-inline-block">
                    ({item.amount}%)
                  </span>
                </Col>
              ))}
            </Row>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ContactSourcesBar;
