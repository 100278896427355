import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Row,
  Tab,
  Nav,
  Form,
  Placeholder,
  Button
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ComparisonEventChart from './ComparisonEventChart';
import SimpleBarReact from 'simplebar-react';
import classNames from 'classnames';
import { emailEvents, periodType } from 'data/common';
import axiosClient from 'services/axios';

const RateNavItems = [
  {
    title: 'Delivery Rate',
    value: 'deliveryRate',
    event: 'Delivery'
  },
  {
    title: 'Open Rate',
    value: 'openRate',
    event: 'Open'
  },
  {
    title: 'Unsubscribe Rate',
    value: 'unsubscribeRate',
    event: 'Unsubscribe'
  },
  {
    title: 'Bounce Rate',
    value: 'bounceRate',
    event: 'Bounce'
  },
  {
    title: 'Complaint Rate',
    value: 'complaintRate',
    event: 'Complaint'
  }
];

const RATE_VIEW = 'rate';
const RAW_VIEW = 'raw';

const TabTitle = ({ title, value, percentage, loading }) => (
  <div className="p-2 pe-4 text-start cursor-pointer">
    <h6 className="text-800 fs-11 text-nowrap">{title}</h6>
    {loading ? (
      <Placeholder as="div" animation="glow">
        <Placeholder xs={12} style={{ width: 70, height: 40 }} />
      </Placeholder>
    ) : (
      <>
        <h5 className="text-800">{value}</h5>
        <Flex alignItems="center">
          {percentage !== 0 && (
            <FontAwesomeIcon
              icon={percentage > 0 ? 'caret-up' : 'caret-down'}
              className={
                percentage > 0 ? 'text-success me-2' : 'text-danger me-2'
              }
            />
          )}
          <h6
            className={`fs-11 mb-0 ${
              percentage > 0 ? 'text-success' : percentage < 0 && 'text-warning'
            }`}
          >
            {Math.abs(percentage.toFixed(1))}%
          </h6>
        </Flex>
      </>
    )}
  </div>
);

const EventRates = ({ className, campaignId = '' }) => {
  const [data, setData] = useState({
    stats: [],
    rate: {}
  });
  const [comparisonData, setComparisonData] = useState({
    stats: [],
    rate: {}
  });

  const [view, setView] = useState(RATE_VIEW);

  const [period, setPeriod] = useState(periodType.LAST_7_DAYS);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('Open');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const formattedPeriod = period.replace(/\s+/g, '_').toLowerCase();
        const [response, optOutResponse] = await Promise.all([
          axiosClient.get(
            `/campaign-events/analyze?period=${formattedPeriod}&campaignId=${campaignId}`
          ),
          axiosClient.get(
            `/campaign-events/opt-out?period=${formattedPeriod}&campaignId=${campaignId}`
          )
        ]);
        const optOutData = optOutResponse.data;

        const _data = response.data.data;
        _data.stats.push({
          eventType: 'Opt out',
          timeSeries: optOutData.data.data,
          total: optOutData.data.total
        });
        setData(_data);

        const _comparisonData = response.data.comparisonData;
        _comparisonData.stats.push({
          eventType: 'Opt out',
          timeSeries: optOutData.comparisonData.data,
          total: optOutData.comparisonData.total
        });
        setComparisonData(_comparisonData);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    fetchData();
  }, [period]);

  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <Tab.Container id="audience-tab" defaultActiveKey="users">
        <SimpleBarReact>
          <Card.Header className="p-0 bg-body-muted">
            <Nav className="nav-tabs border-0 flex-nowrap chart-tab chart-tab-dark">
              {view === RATE_VIEW &&
                RateNavItems.map((item, index) => {
                  const rate = data.rate[item.value];
                  const comparisonRate = comparisonData.rate[item.value];
                  const percentage = comparisonRate
                    ? ((rate - comparisonRate) / comparisonRate) * 100
                    : rate > 0
                    ? 100
                    : 0;
                  return (
                    <Nav.Item key={index}>
                      <Nav.Link
                        className="mb-0"
                        eventKey={item.event}
                        onClick={() => setActiveTab(item.event)}
                        active={item.event === activeTab}
                      >
                        <TabTitle
                          title={item.title}
                          value={`${data.rate[item.value]}%`}
                          percentage={percentage}
                          loading={loading}
                        />
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
              {view === RAW_VIEW &&
                emailEvents.map((event, index) => {
                  const count =
                    data.stats.find(item => item.eventType === event)?.total ||
                    0;
                  const comparisonCount =
                    comparisonData.stats.find(item => item.eventType === event)
                      ?.total || 0;
                  const percentage = comparisonCount
                    ? ((count - comparisonCount) / comparisonCount) * 100
                    : count > 0
                    ? 100
                    : 0;
                  return (
                    <Nav.Item key={index}>
                      <Nav.Link
                        className="mb-0"
                        eventKey={event}
                        onClick={() => setActiveTab(event)}
                        active={event === activeTab}
                      >
                        <TabTitle
                          title={event}
                          value={count.toString()}
                          percentage={percentage}
                          loading={loading}
                        />
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
            </Nav>
          </Card.Header>
        </SimpleBarReact>

        <Card.Body>
          {loading ? (
            <Placeholder as="div" animation="glow">
              <Placeholder xs={12} style={{ height: '21.25rem' }} />
            </Placeholder>
          ) : (
            <Tab.Content>
              {view === RATE_VIEW &&
                ['Delivery', 'Open', 'Unsubscribe', 'Bounce', 'Complaint'].map(
                  (event, index) => (
                    <Tab.Pane
                      unmountOnExit
                      eventKey={event}
                      key={index}
                      active={event === activeTab}
                    >
                      <ComparisonEventChart
                        data={data.stats.find(item => item.eventType === event)}
                        comparisonData={comparisonData.stats.find(
                          item => item.eventType === event
                        )}
                        event={event}
                        period={period}
                      />
                    </Tab.Pane>
                  )
                )}
              {view === RAW_VIEW &&
                emailEvents.map((event, index) => (
                  <Tab.Pane
                    unmountOnExit
                    eventKey={event}
                    key={index}
                    active={event === activeTab}
                  >
                    <ComparisonEventChart
                      data={data.stats.find(item => item.eventType === event)}
                      comparisonData={comparisonData.stats.find(
                        item => item.eventType === event
                      )}
                      event={event}
                      period={period}
                    />
                  </Tab.Pane>
                ))}
            </Tab.Content>
          )}
        </Card.Body>
      </Tab.Container>

      <Card.Footer className="bg-body-muted py-2">
        <Row className="g-0 flex-between-center">
          <Col xs="auto">
            <Form.Select
              size="sm"
              className="me-2"
              onChange={e => {
                setPeriod(e.target.value);
              }}
            >
              <option value={periodType.LAST_7_DAYS}>Last 7 days</option>
              <option value={periodType.LAST_30_DAYS}>Last 30 days</option>
              <option value={periodType.YEAR}>Last Year</option>
            </Form.Select>
          </Col>
          <Button
            style={{ width: 'fit-content' }}
            onClick={() => {
              setView(view === RATE_VIEW ? RAW_VIEW : RATE_VIEW);
              setActiveTab(view === RATE_VIEW ? 'Send' : 'Delivery');
            }}
          >
            See {view === RATE_VIEW ? RAW_VIEW : RATE_VIEW} data
          </Button>
        </Row>
      </Card.Footer>
    </Card>
  );
};

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  loading: PropTypes.bool
};

EventRates.propTypes = {
  className: PropTypes.string.isRequired,
  campaignId: PropTypes.string
};

export default EventRates;
