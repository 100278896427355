import React from 'react';
import PropTypes from 'prop-types';
import { Col, Placeholder, ProgressBar, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { projectsTableKTN } from 'data/dashboard/projectManagement';

const Statistics = ({ data, loading }) => {
  const inProgressCampaign = data?.statusCounts?.find(
    item => item.status === 'in progress'
  );
  const completedCampaign = data?.statusCounts?.find(
    item => item.status === 'completed'
  );
  return (
    <>
      <Row className="mb-2">
        <Col xs={6} className="border-end border-200">
          {loading ? (
            <Placeholder as="h4" animation="glow">
              <Placeholder xs={12} style={{ width: '100px' }} />
            </Placeholder>
          ) : (
            <h4 className="mb-0">{data.total || 0}</h4>
          )}
          <p className="fs-10 text-600 mb-0">Total Campaigns</p>
        </Col>
        <Col xs={3} className="border-end text-center border-200">
          {loading ? (
            <Placeholder as="h4" animation="glow">
              <Placeholder xs={12} style={{ width: '100px' }} />
            </Placeholder>
          ) : (
            <h4 className="fs-9 mb-0">{inProgressCampaign?.count || 0}</h4>
          )}
          <p className="fs-10 text-600 mb-0">In Progress</p>
        </Col>
        <Col className="text-center">
          {loading ? (
            <Placeholder as="h4" animation="glow">
              <Placeholder xs={12} style={{ width: '100px' }} />
            </Placeholder>
          ) : (
            <h4 className="fs-9 mb-0">{completedCampaign?.count || 0}</h4>
          )}
          <p className="fs-10 text-600 mb-0">Ending today</p>
        </Col>
      </Row>
      <ProgressBar
        className="overflow-visible mt-5 rounded-3"
        style={{ height: '6px' }}
      >
        {data.typeCounts
          .filter(item => item.count)
          .map((item, index) => (
            <ProgressBar
              variant={
                projectsTableKTN.find(i => i.project === item.type)?.color ||
                'primary'
              }
              now={item.percentage}
              key={item.type}
              className={classNames('overflow-visible position-relative', {
                'rounded-end rounded-pill': index === 0,
                'rounded-start rounded-pill':
                  index === data.typeCounts.length - 1,
                'border-end border-100 border-2':
                  index !== data.typeCounts.length - 1,
                'rounded-0': index !== data.typeCounts.length - 1 && index !== 0
              })}
              label={
                <span className="mt-n4 text-900 fw-bold">
                  {' '}
                  {item.percentage}%
                </span>
              }
            />
          ))}
      </ProgressBar>
    </>
  );
};

Statistics.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.number,
    statusCounts: PropTypes.arrayOf(PropTypes.any),
    typeCounts: PropTypes.arrayOf(PropTypes.any)
  }),
  loading: PropTypes.bool
};

export default Statistics;
