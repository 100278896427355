import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import WizardInput from 'components/wizard/WizardInput';
import { Col, Row, Form, Button } from 'react-bootstrap'; // Moved Form import to react-bootstrap
import dataTypes from 'data/dataTypes';
import { useForm } from 'react-hook-form';
import { IoCloseCircle } from 'react-icons/io5';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

export const CustomFieldForm = ({
  customFields,
  contact,
  setContact,
  isDisable = false
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();
  const [disableAddButton, setDisableAddButton] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const handleAddNewField = () => {
    handleSubmit(async () => {
      const _contact = { ...contact };
      _contact.customFields.push({
        fieldId: '',
        fieldType: '',
        value: ''
      });

      setContact(_contact);
    })();
  };

  useEffect(() => {
    if (
      contact.customFields[contact.customFields.length - 1] &&
      contact.customFields[contact.customFields.length - 1].fieldId === ''
    ) {
      setDisableAddButton(true);
    } else {
      setDisableAddButton(false);
    }

    if (!initialized && contact?.customFields?.length > 0) {
      contact.customFields.forEach((field, index) => {
        setValue(`fieldName-${index}`, field.fieldId || '');
        setValue(`value-${index}`, field.value || '');
      });
      setInitialized(true);
    }
  }, [contact]);

  return (
    <Form onSubmit={handleAddNewField}>
      {contact.customFields.map((field, index) => (
        <Row key={`custom-field-${index}`} className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Field name</Form.Label>
              <Form.Select
                type="select"
                value={field.fieldId}
                onChange={e => {
                  const _contact = { ...contact };
                  const selectedField = customFields.find(
                    field => field.id === e.target.value
                  );
                  _contact.customFields[index] = {
                    fieldId: selectedField.id,
                    fieldType: selectedField.fieldType,
                    fieldName: selectedField.fieldName,
                    value:
                      selectedField.fieldType === dataTypes.DATE
                        ? dayjs(new Date()).format('MM-DD-YYYY')
                        : ''
                  };
                  setContact(_contact);
                }}
                disabled={isDisable}
                name={`fieldName-${index}`}
              >
                <option value="">Select field</option>
                {customFields.map(option => (
                  <option
                    value={option.id}
                    key={option.id}
                    style={{
                      display:
                        contact.customFields.filter(
                          item => item.fieldName === option.fieldName
                        ).length > 0
                          ? 'none'
                          : 'block'
                    }}
                  >
                    {option.fieldName}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors[`fieldName-${index}`]?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={5}>
            {field.fieldId && (
              <>
                {field.fieldType.toLowerCase() === dataTypes.BOOLEAN ? (
                  <Form.Group>
                    <Form.Label>Value</Form.Label>

                    <Row>
                      {[true, false].map(label => (
                        <Col md="6" key={`radio-${label}`}>
                          <Form.Check
                            type="radio"
                            label={label.toString()}
                            checked={field.value === label}
                            name={`value-${index}`}
                            {...register(`value-${index}`, {
                              required: 'Please select a value'
                            })}
                            onChange={() => {
                              const _contact = { ...contact };
                              _contact.customFields[index].value = label;
                              setContact(_contact);
                              setValue(`value-${index}`, label);
                            }}
                            disabled={isDisable}
                          />
                        </Col>
                      ))}
                      {errors[`value-${index}`] && (
                        <span className="text-danger">
                          {errors[`value-${index}`]?.message}
                        </span>
                      )}
                    </Row>
                  </Form.Group>
                ) : field.fieldType.toLowerCase() === dataTypes.DATE ? (
                  <>
                    <Form.Label>Value</Form.Label>
                    <DatePicker
                      className={`form-control ${
                        errors[`value-${index}`] ? 'is-invalid' : ''
                      }`}
                      disabled={isDisable}
                      selected={
                        field.value
                          ? new Date(field.value.replaceAll('-', '/'))
                          : new Date()
                      }
                      onChange={date => {
                        if (!date) return;
                        const _contact = { ...contact };
                        _contact.customFields[index].value =
                          dayjs(date).format('MM-DD-YYYY');
                        setContact(_contact);
                        setValue(
                          `value-${index}`,
                          dayjs(date).format('MM-DD-YYYY')
                        );
                      }}
                    />
                    {errors[`value-${index}`] && (
                      <div className="invalid-feedback d-block">
                        {errors[`value-${index}`]?.message}
                      </div>
                    )}
                  </>
                ) : (
                  <WizardInput
                    label="Value"
                    name={`value-${index}`}
                    type={field.fieldType.toLowerCase() || 'text'}
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register(`value-${index}`, {
                        required: 'Please enter a value'
                      }),
                      value: field.value,
                      onChange: e => {
                        const _contact = { ...contact };
                        _contact.customFields[index].value = e.target.value;
                        setContact(_contact);
                        setValue(`value-${index}`, e.target.value);
                      },
                      disabled: isDisable
                    }}
                  />
                )}
              </>
            )}
          </Col>
          {field.fieldId && (
            <Col
              md={1}
              className="ps-0"
              style={{
                pointerEvents: isDisable ? 'none' : 'auto',
                opacity: isDisable ? 0.6 : 1,
                cursor: isDisable ? 'not-allowed' : 'pointer'
              }}
              onClick={() => {
                const _contact = { ...contact };
                _contact.customFields.splice(index, 1);
                setContact(_contact);
              }}
            >
              <IoCloseCircle className="icon-delete" />
            </Col>
          )}
        </Row>
      ))}

      <Button
        disabled={disableAddButton || isDisable}
        onClick={handleAddNewField}
      >
        Add new field
      </Button>
    </Form>
  );
};

CustomFieldForm.propTypes = {
  customFields: PropTypes.array.isRequired,
  contact: PropTypes.object.isRequired,
  setContact: PropTypes.func.isRequired,
  isDisable: PropTypes.bool
};

export default CustomFieldForm;
