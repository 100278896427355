import React from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  activeUser,
  transactionSummary,
  grossRevenue,
  candleChartData
} from 'data/dashboard/saas';
import {
  marketShare,
  totalOrder,
  weeklySalesData,
  storageStatus
} from 'data/dashboard/default';
import { audienceChart } from 'data/dashboard/analytics';
import SaasActiveUser from 'components/dashboards/Admin/SaasActiveUser';
import SaasRevenue from 'components/dashboards/Admin/SaasRevenue';
import SaasConversion from 'components/dashboards/Admin/SaasConversion';
import DepositeStatus from 'components/dashboards/Admin/DepositeStatus';
import StatisticsCards from 'components/dashboards/Admin/stats-cards/StatisticsCards';
import { users, files } from 'data/dashboard/default';
import ActiveUsers from 'components/dashboards/default/ActiveUsers';
import SharedFiles from 'components/dashboards/default/SharedFiles';
import BandwidthSaved from 'components/dashboards/default/BandwidthSaved';
import DoMoreCard from 'components/dashboards/Admin/DoMoreCard';
import TransactionSummary from 'components/dashboards/Admin/TransactionSummary';
import GrossRevenue from 'components/dashboards/Admin/gross-revenue/GrossRevenue';
import CandleChart from 'components/dashboards/Admin/candle-chart/CandleChart';
import WeeklySales from 'components/dashboards/default/WeeklySales';
import TotalOrder from 'components/dashboards/default/TotalOrder';
import MarketShare from 'components/dashboards/default/MarketShare';
import StorageStatus from 'components/dashboards/default/StorageStatus';
import Audience from 'components/dashboards/Admin/audience/Audience';
const Saas = () => {
  return (
    <>
      <StatisticsCards />
      <Row className="g-3 mb-3">
        <Col md={6} lg={4} xl={6} xxl={4}>
          <WeeklySales data={weeklySalesData} />
        </Col>
        <Col md={6} lg={4} xl={6} xxl={4}>
          <TotalOrder data={totalOrder} />
        </Col>
        <Col md={6} lg={4} xl={6} xxl={4}>
          <MarketShare data={marketShare} radius={['100%', '87%']} />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col xxl={4}>
          <SaasActiveUser data={activeUser} />
        </Col>
        <Col xxl={4}>
          <SaasRevenue />
        </Col>
        <Col xxl={4}>
          <SaasConversion />
        </Col>
        <Col xxl={4}>
          <SaasActiveUser data={activeUser} />
        </Col>
        <Col xxl={4}>
          <SaasRevenue />
        </Col>
        <Col xxl={4}>
          <SaasConversion />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col xl={12}>
          <StorageStatus className="h-lg-100" data={storageStatus} />
        </Col>

        <Col xxl={12}>
          <Audience chartData={audienceChart} className="h-100" />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col xxl={9}>
          <DepositeStatus />
          <StatisticsCards />
          <Row className="g-3">
            <Col xs={12}>
              <CandleChart data={candleChartData} />
            </Col>
            <Col lg={4}>
              <ActiveUsers users={users} end={7} />
            </Col>
            <Col lg={8}>
              <GrossRevenue data={grossRevenue} />
            </Col>
          </Row>
        </Col>
        <Col xxl={3}>
          <Row className="g-3">
            <Col xxl={12}>
              <SharedFiles
                files={files}
                className="h-100 h-xxl-auto mt-xxl-3"
              />
            </Col>
            <Col md={6} xxl={12}>
              <BandwidthSaved />
            </Col>
            <Col md={6} xxl={12}>
              <DoMoreCard />
            </Col>
          </Row>
        </Col>
      </Row>
      <TransactionSummary data={transactionSummary} />
    </>
  );
};

export default Saas;
