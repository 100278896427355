import React from 'react';
import PropTypes from 'prop-types';
import { Card, Placeholder } from 'react-bootstrap';
import classNames from 'classnames';
import EventChart from './EventChart';

const EventSection = ({
  chartData,
  className,
  period,
  loading,
  event,
  description,
  ...props
}) => {
  return (
    <Card className={classNames(className, 'overflow-hidden')} {...props}>
      <Card.Header className="border-bottom">
        <h5 className="mb-0 mt-2">{event}</h5>
        <p className="mb-0 fs-11">{description}</p>
      </Card.Header>

      <Card.Body>
        {loading ? (
          <Placeholder as="div" animation="glow">
            <Placeholder xs={12} style={{ height: '21.25rem' }} />
          </Placeholder>
        ) : (
          <EventChart data={chartData} event={event} period={period} />
        )}
      </Card.Body>
    </Card>
  );
};

EventSection.propTypes = {
  props: PropTypes.object,
  chartData: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  event: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default EventSection;
