import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'redux/store';
import { selectToken } from 'redux/reducer/auth';

const AuthRoute = () => {
  const token = useAppSelector(selectToken);

  return !token ? <Outlet /> : <Navigate to="/" />;
};

export default AuthRoute;
