import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleBarReact from 'simplebar-react';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import { contactEventsIcon } from 'data/common';
import dayjs from 'dayjs';
import { faHandPointer } from '@fortawesome/free-solid-svg-icons';
import { formatTimeAgo } from 'helpers/utils';
const Activity = ({
  activity: { title, text, icon, bg, iconColor, time, status },
  isLast
}) => {
  return (
    <Row
      className={classNames(
        'g-3 recent-activity-timeline recent-activity-timeline-primary',
        {
          'pb-x1': !isLast,
          'recent-activity-timeline-past': status === 'completed',
          'recent-activity-timeline-current': status === 'current'
        }
      )}
    >
      <Col xs="auto" className="ps-4 ms-2">
        <div className="ps-2">
          <div
            className="icon-item icon-item-sm rounded-circle shadow-none"
            style={{ backgroundColor: bg }}
          >
            <FontAwesomeIcon
              icon={icon || faHandPointer}
              className={iconColor}
            />
          </div>
        </div>
      </Col>
      <Col>
        <Row className={classNames('g-3', { 'border-bottom pb-x1': !isLast })}>
          <Col>
            <h6 className="text-800 mb-1">{title}</h6>
            <p
              className="fs-10 text-600 mb-0"
              dangerouslySetInnerHTML={{
                __html: text
              }}
            />
          </Col>
          <Col xs="auto">
            <p className="fs-11 text-500 mb-0">{time}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const RecentActivity = ({ contact }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const transformData = (rawData = []) => {
    return rawData
      .map((item, index) => {
        const adjustedEventType =
          item.eventType === 'Send'
            ? 'Sent'
            : item.eventType === 'Delivery'
            ? 'Delivered'
            : item.eventType;
        const eventIconConfig = contactEventsIcon[adjustedEventType] || {};

        return {
          id: index,
          title: `Email ${adjustedEventType.toLowerCase()}`,
          text: `Campaign: <a href="/campaigns?id=${item.campaignId}">${item.campaignName}</a>`,
          time: formatTimeAgo(item.createdAt),
          status: 'completed',
          ...eventIconConfig
        };
      })
      .concat({
        id: rawData.length,
        title: 'Created at',
        text: `Contact created at ${dayjs(contact.createdAt).format(
          'MM/DD/YYYY HH:mm A'
        )}`,
        time: formatTimeAgo(contact.createdAt),
        status: 'completed',
        icon: 'clock',
        bg: '#d9e7fa',
        iconColor: 'text-primary'
      });
  };

  const fetchCampaignEventsByContact = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get(
        `campaign-events/contact/${contact.id}`
      );
      setData(transformData(response.data));
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to fetch campaign event by contact');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (contact?.id) {
      fetchCampaignEventsByContact();
    }
  }, [contact]);

  return (
    <Card
      className="h-auto recent-activity-card"
      style={{ maxHeight: '28rem', width: '-webkit-fill-available' }}
    >
      <FalconCardHeader title="Recent Activity" titleTag="h5" />
      <SimpleBarReact style={{ maxHeight: '28rem' }}>
        {loading ? (
          <Row className="justify-content-center mb-3 g-3">
            <Col lg={4} className="d-flex justify-content-center">
              <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
            <h3 className="text-center">Loading...</h3>
          </Row>
        ) : (
          <Card.Body
            className="ps-2 pb-5 overflow-y-auto"
            style={{ maxHeight: '20.5rem' }}
          >
            {data?.map((activity, index) => (
              <Activity
                key={activity.id}
                activity={activity}
                isLast={index === data?.length - 1}
              />
            ))}
          </Card.Body>
        )}
      </SimpleBarReact>
    </Card>
  );
};

Activity.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    bg: PropTypes.string.isRequired,
    iconColor: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
  }),
  isLast: PropTypes.bool
};

RecentActivity.propTypes = {
  contact: PropTypes.object
};

export default RecentActivity;
