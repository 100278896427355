import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Tab, Nav, Form, Placeholder } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SimpleBarReact from 'simplebar-react';
import { emailContactEvents, periodType } from 'data/common';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import EventChart from 'components/dashboard/events/EventChart';

const TabTitle = ({ title, value, isLoading }) => (
  <div className="p-2 pe-4 text-start cursor-pointer">
    <h6 className="text-800 fs-11 text-nowrap text-capitalize ">{title}</h6>
    {isLoading ? (
      <Placeholder as="h5" animation="glow">
        <Placeholder xs={12} style={{ width: '50px' }} />
      </Placeholder>
    ) : (
      <h5 className="text-800" style={{ width: '50px' }}>
        {value}
      </h5>
    )}
  </div>
);

const Metrics = ({ contact }) => {
  const [emailEventPeriod, setEventPeriod] = useState(periodType.LAST_7_DAYS);
  const [isEventFetching, setIsEventFetching] = useState(false);
  const [events, setEvents] = useState([]);

  const fetchEvent = async () => {
    setIsEventFetching(true);
    const formattedPeriod = emailEventPeriod.replace(/\s+/g, '_').toLowerCase();
    try {
      const response = await axiosClient.get(
        `/campaign-events?contactId=${contact.id}&period=${formattedPeriod}`
      );
      const rawData = response.data.data;
      setEvents(rawData);
    } catch (error) {
      const message = error.response?.data?.message || 'Error fetching data';
      toast.error(message);
    } finally {
      setIsEventFetching(false);
    }
  };

  useEffect(() => {
    if (contact?.id && emailEventPeriod) {
      fetchEvent();
    }
  }, [contact, emailEventPeriod]);

  return (
    <Card>
      <Card.Header className="bg-body-muted">
        <Flex justifyContent="between">
          <h5 className="mb-1 mb-md-0">Metrics</h5>
        </Flex>
      </Card.Header>
      <Tab.Container id="Event-tab" defaultActiveKey={emailContactEvents[0]}>
        <SimpleBarReact>
          <Card.Header className="p-0 bg-body-muted">
            <Nav className="nav-tabs border-0 flex-nowrap chart-tab chart-tab-dark">
              {emailContactEvents.map((event, index) => (
                <Nav.Item key={index}>
                  <Nav.Link className="mb-0" eventKey={event}>
                    <TabTitle
                      title={event}
                      value={
                        events.find(item => item.eventType === event)?.total ||
                        0
                      }
                      isLoading={isEventFetching || !contact}
                    />
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>
        </SimpleBarReact>

        <Card.Body>
          <Tab.Content>
            {isEventFetching || !contact ? (
              <Placeholder as="Tab.Pane" animation="glow">
                <Placeholder xs={12} style={{ height: '21.25rem' }} />
              </Placeholder>
            ) : (
              emailContactEvents.map((event, index) => (
                <Tab.Pane unmountOnExit eventKey={event} key={index}>
                  <EventChart
                    data={
                      events.find(item => item.eventType === event)
                        ?.timeSeries || []
                    }
                    event={event}
                    period={emailEventPeriod}
                  />
                </Tab.Pane>
              ))
            )}
          </Tab.Content>
        </Card.Body>
      </Tab.Container>

      <Card.Footer className="bg-body-muted py-2">
        <Row className="g-0 flex-between-center">
          <Col xs="auto">
            <Form.Select
              size="sm"
              className="me-2"
              onChange={e => {
                setEventPeriod(e.target.value);
              }}
            >
              <option>{periodType.LAST_7_DAYS}</option>
              <option>{periodType.LAST_30_DAYS}</option>
              <option>{periodType.ALL_TIME}</option>
            </Form.Select>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};

Metrics.propTypes = {
  contact: PropTypes.object.isRequired
};

export default Metrics;
