import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Table,
  Card,
  Button,
  Dropdown,
  Spinner,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { FaEdit, FaCaretUp, FaCaretDown } from 'react-icons/fa';
import { GoCheckCircle } from 'react-icons/go';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import './styles.scss';
import { useAppSelector } from 'redux/store';
import { selectUser } from 'redux/reducer/auth';
import CustomPagination from 'components/pagination';
import SubtleBadge from 'components/common/SubtleBadge';
import classNames from 'classnames';
import {
  OrganizationStatusType,
  EmailSendingStatus,
  OrganizationRole
} from 'data/common';
import OrganizationModal from 'components/organizations/organizationModal';

const ASC = 'ASC';
const DESC = 'DESC';

const OrganizationsPage = () => {
  const user = useAppSelector(selectUser);
  const [isFetching, setIsFetching] = useState(true);

  const [organizations, setOrganizations] = useState([]);

  const [showOrganizationModal, setShowOrganizationModal] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [mode, setMode] = useState('ADD');
  const [organizationDetail, setOrganizationDetail] = useState();
  const [sort, setSort] = useState({
    fieldName: 'name',
    order: ASC
  });

  const fetchOrganizations = async () => {
    setIsFetching(true);
    try {
      const response = await axiosClient.get(
        `/organizations?page=${page}&limit=${limit}&search=${search}&sort=${sort.fieldName}:${sort.order}`
      );
      const sortedOrgs = response.data.organizations.sort((a, b) => {
        if (a.ownerId === user.id) return -1;
        if (b.ownerId === user.id) return 1;
        return a.organizationRoles[0].role.localeCompare(
          b.organizationRoles[0].role
        );
      });
      setOrganizations(sortedOrgs);
      setTotal(response.data.total);
    } catch (error) {
      console.error('Error to get organizations:', error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get organizations');
      }
    }
    setIsFetching(false);
  };

  const getArrowStyles = (fieldName, order) => {
    if (sort.fieldName === fieldName && sort.order === order) {
      return {
        color: '#748194'
      };
    } else {
      return {
        color: '#d8e2ef'
      };
    }
  };

  const onSortChange = fieldName => {
    if (sort.fieldName === fieldName) {
      setSort({
        fieldName,
        order: sort.order === ASC ? DESC : ASC
      });
    } else {
      setSort({
        fieldName,
        order: ASC
      });
    }
  };

  useEffect(() => {
    if (limit) {
      setPageCount(Math.ceil(total / limit));
    }
  }, [limit, total]);

  useEffect(() => {
    if (user) {
      fetchOrganizations();
    }
  }, [limit, page, user, sort]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
      setPage(1);
    }, 300);

    // Clear the timeout if search changes (before the delay ends)
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (user) {
      fetchOrganizations();
    }
  }, [debouncedSearch]);

  return (
    <>
      <h4 className="">Organizations</h4>
      <Row className="g-3 mb-3">
        <Col md={12}>
          <Card className="overflow-hidden">
            <Card.Header className="col-12 d-flex flex-wrap gap-2 justify-content-between border-bottom">
              <input
                className="input-value search-input-value user-input-value"
                label="Value"
                name="value-0"
                type="text"
                placeholder="Search for name"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </Card.Header>
            <Card.Body>
              <Table
                responsive
                style={{
                  height:
                    isFetching || organizations.length < 0
                      ? 'calc(100vh - 250px)'
                      : ''
                }}
              >
                <thead>
                  <tr>
                    <th scope="col" className="cursor-pointer text-center">
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 80 }}
                      >
                        Role
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={{ color: '#d8e2ef' }} />
                          <FaCaretDown style={{ color: '#748194' }} />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('name')}
                    >
                      <div className="d-flex" style={{ minWidth: 200 }}>
                        Name
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('name', ASC)} />
                          <FaCaretDown style={getArrowStyles('name', DESC)} />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('tier_plan')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 80 }}
                      >
                        Tier plan
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('tier_plan', ASC)} />
                          <FaCaretDown
                            style={getArrowStyles('tier_plan', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('status')}
                    >
                      <div className="d-flex justify-content-between">
                        Status
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('status', ASC)} />
                          <FaCaretDown style={getArrowStyles('status', DESC)} />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('business_name')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 150 }}
                      >
                        Business name
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('business_name', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('business_name', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('business_address')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 200 }}
                      >
                        Business address
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('business_address', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('business_address', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('business_type')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 150 }}
                      >
                        Business type
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('business_type', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('business_type', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('state')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 100 }}
                      >
                        State
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('state', ASC)} />
                          <FaCaretDown style={getArrowStyles('state', DESC)} />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('zip_code')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 80 }}
                      >
                        Zip code
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('zip_code', ASC)} />
                          <FaCaretDown
                            style={getArrowStyles('zip_code', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('city')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 100 }}
                      >
                        City
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('city', ASC)} />
                          <FaCaretDown style={getArrowStyles('city', DESC)} />
                        </span>
                      </div>
                    </th>

                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('website')}
                    >
                      <div className="d-flex justify-content-between">
                        Website
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('website', ASC)} />
                          <FaCaretDown
                            style={getArrowStyles('website', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('sending_limit')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 120 }}
                      >
                        Sending limit
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('sending_limit', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('sending_limit', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('email_sending_status')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 200 }}
                      >
                        Email sending status
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('email_sending_status', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('email_sending_status', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('email_sending_enabled')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 200 }}
                      >
                        Email sending enabled
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('email_sending_enabled', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles(
                              'email_sending_enabled',
                              DESC
                            )}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('text_sending_enabled')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 200 }}
                      >
                        Text sending enabled
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('text_sending_enabled', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('text_sending_enabled', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isFetching ? (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : organizations.length > 0 ? (
                    organizations.map((organization, index) => {
                      return (
                        <tr key={`organization-${index}`}>
                          <td>
                            {organization.ownerId === user.id
                              ? 'Owner'
                              : user.isAdmin
                              ? 'Admin'
                              : organization?.organizationRoles[0].role}
                          </td>
                          <td>{organization.name}</td>
                          <td>
                            <SubtleBadge
                              bg={classNames({
                                success: organization.tierPlan === 'Tier 1',
                                info: organization.tierPlan === 'Tier 2',
                                warning: organization.tierPlan === 'Tier 3'
                              })}
                              className="me-1"
                            >
                              {organization.tierPlan}
                            </SubtleBadge>
                          </td>
                          <td>
                            <SubtleBadge
                              bg={classNames({
                                primary:
                                  organization.status ===
                                  OrganizationStatusType.ACTIVE,
                                secondary:
                                  organization.status ===
                                  OrganizationStatusType.DISABLED
                              })}
                              className="me-1"
                            >
                              {organization.status}
                            </SubtleBadge>
                          </td>
                          <td>{organization.businessName}</td>
                          <td>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`businessAddress-${index}`}>
                                  {organization.businessAddress}
                                </Tooltip>
                              }
                            >
                              <span>
                                {organization.businessAddress.length > 20
                                  ? organization.businessAddress.substring(
                                      0,
                                      20
                                    ) + '...'
                                  : organization.businessAddress}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td>{organization.businessType}</td>
                          <td>{organization.state}</td>
                          <td>{organization.zipCode}</td>
                          <td>{organization.city}</td>
                          <td>{organization.website}</td>

                          <td>{organization.sendingLimit.toLocaleString()}</td>
                          <td>
                            <SubtleBadge
                              bg={classNames({
                                success:
                                  organization.emailSendingStatus ===
                                  EmailSendingStatus.ACTIVE,
                                secondary:
                                  organization.emailSendingStatus ===
                                  EmailSendingStatus.SUSPENDED,
                                danger:
                                  organization.emailSendingStatus ===
                                  EmailSendingStatus.WARNED,
                                warning:
                                  organization.emailSendingStatus ===
                                  EmailSendingStatus.PRE_WARNED
                              })}
                              className="me-1"
                            >
                              {organization.emailSendingStatus}
                            </SubtleBadge>
                          </td>
                          <td>
                            <div className="d-flex justify-content-center">
                              {organization.emailSendingEnabled ? (
                                <GoCheckCircle className="text-success fs-9" />
                              ) : (
                                <IoIosCloseCircleOutline className="text-danger fs-8" />
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-center">
                              {organization.textSendingEnabled ? (
                                <GoCheckCircle className="text-success fs-9" />
                              ) : (
                                <IoIosCloseCircleOutline className="text-danger fs-8" />
                              )}
                            </div>
                          </td>
                          <td className="px-0">
                            {(organization.organizationRoles[0].role ===
                              OrganizationRole.ADMIN ||
                              user.isAdmin) && (
                              <div className="d-flex gap-2 me-1">
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip
                                      style={{
                                        position: 'fixed',
                                        zIndex: 1000
                                      }}
                                      id={`edit-button-${index}`}
                                    >
                                      Edit
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="outline-info"
                                    onClick={e => {
                                      e.stopPropagation();
                                      setShowOrganizationModal(true);
                                      setMode('EDIT');
                                      setOrganizationDetail(organization);
                                    }}
                                  >
                                    <FaEdit />
                                  </Button>
                                </OverlayTrigger>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        No organizations found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="position-relative">
                <CustomPagination
                  page={page}
                  pageCount={pageCount}
                  setPage={setPage}
                />
                {organizations.length > 0 && (
                  <Dropdown
                    className="segment-dropdown position-absolute top-0 end-0"
                    style={{ minWidth: '70px' }}
                    onSelect={e => {
                      setLimit(e);
                      setPage(1);
                    }}
                  >
                    <Dropdown.Toggle variant="falcon-default text-capitalize">
                      {limit}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="py-2">
                      {[10, 20, 50, 100].map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={item}
                          className="text-capitalize"
                        >
                          {item}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {organizationDetail && showOrganizationModal && (
        <OrganizationModal
          showOrganizationModal={showOrganizationModal}
          setShowOrganizationModal={setShowOrganizationModal}
          fetchOrganizations={fetchOrganizations}
          organizationDetail={organizationDetail}
          mode={mode}
        />
      )}
    </>
  );
};

export default OrganizationsPage;
