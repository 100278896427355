import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/img/illustrations/KITN.png';

const WaitListCaptchaPage = () => {
  const navigate = useNavigate();
  const [captchaToken, setCaptchaToken] = useState('');
  const [isRecaptchaReady, setIsRecaptchaReady] = useState(false); // For loading indicator

  // Ensure this is a v2 SITE KEY from the Google reCAPTCHA admin console
  const siteKey = '6Lf7KMMqAAAAABHABV77aGVDvlfjctSXRknS8WdL';
  //process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const handleCaptchaChange = token => {
    setCaptchaToken(token);
  };

  // Called when the reCAPTCHA script has loaded
  const handleRecaptchaLoad = () => {
    console.log('reCAPTCHA script loaded!');
    setIsRecaptchaReady(true);
  };

  // Continue button handler
  const handleContinue = () => {
    if (!captchaToken) {
      alert('Please complete reCAPTCHA first.');
      return;
    }
    localStorage.setItem('recaptchaToken', captchaToken);
    navigate('/waitlist-form');
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: 'black',
        fontFamily: 'Arial, sans-serif',
        textAlign: 'center',
        padding: '1rem'
      }}
    >
      {/* Logo Section */}
      <img
        src={logo}
        alt="Keep in touch now"
        style={{
          width: '150px',
          marginBottom: '2rem'
        }}
      />

      {/* Page Heading */}
      <h1
        style={{
          color: 'silver',
          fontSize: '1.8rem',
          marginBottom: '1.5rem'
        }}
      >
        Please verify you are human
      </h1>

      {/* reCAPTCHA Widget */}
      <div style={{ marginBottom: '1.5rem' }}>
        <ReCAPTCHA
          sitekey={siteKey}
          onChange={handleCaptchaChange}
          asyncScriptOnLoad={handleRecaptchaLoad}
        />
        {/* If not yet loaded, show a loading message */}
        {!isRecaptchaReady && (
          <p style={{ color: 'white', marginTop: '1rem' }}>
            Loading reCAPTCHA please wait ...
          </p>
        )}
      </div>

      {/* Continue Button */}
      <button
        type="button"
        onClick={handleContinue}
        style={{
          padding: '0.8rem 2rem',
          fontSize: '1rem',
          backgroundColor: '#007BFF',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease'
        }}
        onMouseOver={e => (e.target.style.backgroundColor = '#0056b3')}
        onMouseOut={e => (e.target.style.backgroundColor = '#007BFF')}
      >
        Continue
      </button>
    </div>
  );
};

export default WaitListCaptchaPage;
