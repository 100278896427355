import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Placeholder } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import CardDropdown from 'components/common/CardDropdown';
import { statsData } from 'data/dashboard/crm';
import classNames from 'classnames';
import IconItem from 'components/common/icon/IconItem';
import StatsChart from '../dashboards/marketing/StatsChart';
import { useAppContext } from 'providers/AppProvider';
import dayjs from 'dayjs';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';

const CardItem = ({ stat, loading }) => {
  const { icon, color, title, amount, caret, caretColor, target, data } = stat;
  const { getThemeColor } = useAppContext();
  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        className="mb-3 position-static"
      >
        <IconItem
          tag="div"
          icon={icon}
          bg={`${color}-subtle`}
          color={color}
          size="sm"
          iconClass="fs-11"
          className="me-2 shadow-none"
        />
        <h6 className="mb-0 flex-1">{title}</h6>
        {/* <div>
          <CardDropdown />
        </div> */}
      </Flex>
      <Flex style={{ width: '100%' }}>
        {loading ? (
          <Placeholder
            className="mb-1 pe-2"
            as="div"
            animation="glow"
            style={{ width: '100%' }}
          >
            <Placeholder xs={12} style={{ height: '35px' }} />
          </Placeholder>
        ) : (
          <>
            <p className="font-sans-serif lh-1 mb-1 fs-5 pe-2">{amount}</p>
            <div className="d-flex flex-column">
              <FontAwesomeIcon
                icon={caret}
                className={`me-1 mb-0 text-${caretColor}`}
              />
              <p className={`fs-11 mb-0 mt-0 text-nowrap text-${caretColor}`}>
                {target}
              </p>
            </div>
            <div className="w-100 ms-2">
              <StatsChart color={getThemeColor(color)} data={data} />
            </div>
          </>
        )}
      </Flex>
    </>
  );
};

const ContactCard = ({ period }) => {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState(statsData);

  const updateStat = (data, stat, key) => {
    const comparisonCount = parseFloat(data[key]?.comparisonCount || 0);
    const caret = comparisonCount >= 0 ? 'caret-up' : 'caret-down';
    const caretColor = caret === 'caret-up' ? 'success' : 'danger';

    return {
      ...stat,
      amount: data[key]?.totalCount || 0,
      target: `${Math.abs(comparisonCount)}%`,
      caret,
      caretColor,
      data: {
        count: data[key]?.timeSeries?.map(item => item.count) || [],
        timePeriod:
          data[key]?.timeSeries?.map(item =>
            period === 'Year to date'
              ? dayjs(item.timePeriod).format('YYYY MMM')
              : dayjs(item.timePeriod).format('MM/DD/YYYY')
          ) || []
      }
    };
  };

  const fetchContactsStats = async () => {
    setLoading(true);
    const formattedPeriod = period.replace(/\s+/g, '_').toLowerCase();
    try {
      const response = await axiosClient.get(
        `contacts/stats?period=${formattedPeriod}`
      );
      setStats(prevStats =>
        prevStats.map(stat => {
          switch (stat.title) {
            case 'New Contacts':
              return updateStat(response.data, stat, 'newContacts');
            case 'Active Contacts':
              return updateStat(response.data, stat, 'activeContacts');
            case 'Contacts loss':
              return updateStat(response.data, stat, 'contactsLoss');
            default:
              return stat;
          }
        })
      );
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to fetch contact stats');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContactsStats();
  }, [period]);

  return (
    <Card>
      <Card.Body>
        <Row>
          {stats.map((stat, index) => (
            <Col
              lg={4}
              key={stat.id}
              className={classNames({
                'border-bottom border-lg-0 border-lg-end':
                  index !== stats.length - 1,
                'pb-3 pb-lg-0': index === 0,
                'py-3 py-lg-0': index === 1,
                'pt-3 pt-lg-0': index === 2
              })}
            >
              <CardItem stat={stat} loading={loading} />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

CardItem.propTypes = {
  stat: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    caret: PropTypes.string.isRequired,
    caretColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    icon: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    target: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  loading: PropTypes.bool
};

ContactCard.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.shape(CardItem.propTypes.stat)),
  period: PropTypes.string
};

export default ContactCard;
