import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Section from 'components/common/Section';
import Logo from 'components/common/Logo';
import Lottie from 'lottie-react';
import like from '../../components/wizard/lottie/like.json';
import './styles.scss';

const RegisterSuccessPage = () => {
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
          <Logo />
          <Card className="theme-wizard text-center">
            <Card.Body className="p-5">
              <Row>
                <Col className="text-center">
                  <div className="wizard-lottie-wrapper">
                    <div className="wizard-lottie wizard-lottie-like mx-auto">
                      <Lottie animationData={like} loop={true} />
                    </div>
                  </div>
                </Col>
              </Row>
              <p className="lead mt-4 text-800 font-sans-serif fw-semibold">
                Thank You for Signing Up
              </p>
              <hr />
              <p>
                Thank you for signing up! We sent you an email with a
                confirmation link. Please check your inbox. If you don’t see it,
                check your spam or junk folder. If you still don’t see it after
                10 minutes, come back to this page.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default RegisterSuccessPage;
