import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Section from 'components/common/Section';
import Logo from 'components/common/Logo';
import Lottie from 'lottie-react';
import contact from '../../components/wizard/lottie/contact-support.json';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'redux/store';
import { selectUser, logout } from 'redux/reducer/auth';

const ContactSupportPage = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    const { resendCount } = user;
    if (resendCount < 3) {
      navigate('/resend-confirmation-email');
    }
  }, [user]);
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
          <Logo />
          <Card className="theme-wizard text-center">
            <Card.Body className="p-5">
              <Row>
                <Col className="text-center">
                  <div className="wizard-lottie-wrapper">
                    <div className="wizard-lottie wizard-lottie-contact mx-auto">
                      <Lottie animationData={contact} loop={true} />
                    </div>
                  </div>
                </Col>
              </Row>
              <p className="lead mt-4 text-800 font-sans-serif fw-semibold">
                Contact Support
              </p>
              <hr />
              <p>
                You’ve reached the maximum number of resend attempts. Please
                contact support at{' '}
                <Link to="mailto:support@keepintouchnow.com">
                  support@keepintouchnow.com
                </Link>{' '}
                for further assistance.
              </p>
            </Card.Body>
          </Card>
          <Link
            to="/login"
            className="d-flex mx-auto mt-4 text-center fs-10"
            onClick={() => {
              dispatch(logout());
              navigate('/login');
            }}
            style={{ width: 'fit-content' }}
          >
            Back to login
          </Link>
        </Col>
      </Row>
    </Section>
  );
};

export default ContactSupportPage;
