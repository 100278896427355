import React from 'react';
import { Row, Placeholder } from 'react-bootstrap';
import ProfileBanner from './ContactBanner';
import { selectOrganization } from 'redux/reducer/auth';
import { useAppSelector } from 'redux/store';
import userAvatar from 'assets/img/icons/user.png';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const Banner = ({ contact }) => {
  const organization = useAppSelector(selectOrganization);
  return (
    <ProfileBanner>
      <ProfileBanner.Header
        avatar={userAvatar}
        coverSrc={organization.companyLogo}
        name={contact && `${contact.firstName} ${contact.lastName}`}
      />
      <ProfileBanner.Body>
        <Row>
          {!contact ? (
            <Placeholder as="h4" animation="glow">
              <Placeholder xs={12} style={{ width: '200px' }} />
            </Placeholder>
          ) : (
            <h4 className="mb-1">
              {contact.firstName} {contact.lastName}
            </h4>
          )}

          {!contact ? (
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} style={{ width: '200px' }} />
            </Placeholder>
          ) : (
            <p className="text-500 mb-2">{contact.email}</p>
          )}
          <div className="mb-1">
            <strong className="fs-9">Source: </strong>
            <span className="fs-9">{contact && contact.source}</span>
          </div>

          {!contact ? (
            <Placeholder as="h5" animation="glow">
              <Placeholder xs={12} style={{ width: '200px' }} />
            </Placeholder>
          ) : (
            <div className="mb-1">
              <strong className="fs-9">Created date:</strong>
              <span className="fs-9">
                {dayjs(contact.createdAt).format('MM/DD/YYYY HH:mm')}
              </span>
            </div>
          )}
          {contact && contact.lastContactedDate && (
            <div className="mb-1">
              <strong className="fs-9">Last contact date:</strong>
              <span className="fs-9">
                {dayjs(contact.lastContactedDate).format('MM/DD/YYYY HH:mm')}
              </span>
            </div>
          )}

          <div className="border-dashed border-bottom my-4 d-lg-none" />
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

Banner.propTypes = {
  contact: PropTypes.object
};

export default Banner;
