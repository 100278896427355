import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Modal, Nav, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';

import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import CampaignForm from './wizard/CampaignForm';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import { selectUser } from 'redux/reducer/auth';
import { useAppSelector } from 'redux/store';
import ScheduleForm from './wizard/ScheduleForm';
import Review from './wizard/Review';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import dayjs from 'dayjs';
import { ExecutionType } from 'data/common';

const AddCampaignModal = ({
  showAddCampaignModal,
  setShowAddCampaignModal,
  fetchCampaigns,
  emailSenders,
  textSenders,
  templates,
  segments,
  groups,
  contacts
}) => {
  const {
    register,
    unregister,
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm();

  const navItems = [
    {
      icon: 'flag',
      label: 'Campaign'
    },
    {
      icon: 'user',
      label: 'Schedule'
    },
    {
      icon: 'thumbs-up',
      label: 'Review'
    }
  ];
  const initNewCampaign = {
    name: '',
    description: '',
    campaignType: '',
    objective: '',
    communicationTypes: [],
    campaignExecutions: [],
    scheduledStartDate: dayjs(new Date()).format('MM/DD/YYYY HH:mm'),
    numberOfExecutions: null,
    recurrencePattern: '',
    emailSender: '',
    textSender: ''
  };

  const user = useAppSelector(selectUser);
  const { isRTL } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState(initNewCampaign);
  const [step, setStep] = useState(1);
  const [executionError, setExecutionError] = useState(null);
  const [isSaved, setIsSaved] = useState(false);

  const onSubmitData = async () => {
    setCampaign(campaign);
    if (step < 3) {
      if (step === 2) {
        if (
          !campaign.campaignExecutions.find(execution =>
            execution.campaignCommunications.find(item => item.enabled)
          )
        ) {
          return;
        }
        if (
          campaign.campaignExecutions.find(
            execution =>
              !execution.segmentIds.length &&
              !execution.groupIds.length &&
              !execution.contactIds.length
          )
        ) {
          campaign.campaignExecutions.forEach(execution => {
            const type = execution.type || ExecutionType.AUTOMATIC;
            const index = execution.index;
            if (
              !execution.segmentIds.length &&
              !execution.groupIds.length &&
              !execution.contactIds.length
            ) {
              setError(`${type}-contact-${index}`, {
                type: 'manual',
                message:
                  'Please setup contacts by select at least one segment/group/contact'
              });
            } else {
              clearErrors(`${type}-contact-${index}`);
            }
          });
          return;
        }
        if (campaign.campaignExecutions.length === 0) {
          setExecutionError('Please add at least one execution');
          toast.error('Please add at least one execution');
          return;
        } else {
          setExecutionError('');
        }
      }
      setStep(step + 1);
    } else {
      setLoading(true);
      try {
        const _campaign = { ...campaign };
        _campaign.status = 'review';
        _campaign.campaignExecutions = campaign.campaignExecutions.map(item => {
          const execution = {
            ...item,
            contacts: [],
            scheduledStartDate: new Date(item.scheduledStartDate).toISOString(),
            campaignCommunications: item.campaignCommunications.filter(
              item => item.enabled
            )
          };
          if (item.endDate) {
            execution.endDate = new Date(item.endDate).toISOString();
          }
          return execution;
        });

        await axiosClient.post('/campaigns', _campaign);
        toast.success('Campaign created successfully');
        setIsSaved(true);
        setShowAddCampaignModal(false);
        fetchCampaigns();
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Error to create campaign');
        }
      }
    }
  };

  const autoSaveCampaign = async () => {
    if (campaign.status || isSaved || step <= 1) return;
    const _campaign = { ...campaign };
    if (step === 2) {
      _campaign.status = 'draft';
    } else if (step > 2) {
      _campaign.status = 'review';
    }
    _campaign.campaignExecutions = campaign.campaignExecutions.map(item => {
      const execution = {
        ...item,
        contacts: [],
        scheduledStartDate: new Date(item.scheduledStartDate).toISOString(),
        campaignCommunications: item.campaignCommunications.filter(
          item => item.enabled
        )
      };
      if (item.endDate) {
        execution.endDate = new Date(item.endDate).toISOString();
      }
      return execution;
    });
    await axiosClient.post('/campaigns', _campaign);
    fetchCampaigns();
    setIsSaved(true);
  };
  const handleSaveCampaignAsDraft = async () => {
    setLoading(true);
    try {
      const _campaign = { ...campaign };
      _campaign.status = 'draft';
      _campaign.campaignExecutions = campaign.campaignExecutions.map(item => {
        const execution = {
          ...item,
          contacts: [],
          scheduledStartDate: new Date(item.scheduledStartDate).toISOString(),
          campaignCommunications: item.campaignCommunications.filter(
            item => item.enabled
          )
        };
        if (item.endDate) {
          execution.endDate = new Date(item.endDate).toISOString();
        }
        return execution;
      });

      await axiosClient.post('/campaigns', _campaign);
      toast.success('Campaign saved as draft');
      setShowAddCampaignModal(false);
      fetchCampaigns();
      setLoading(false);
      setIsSaved(true);
    } catch (error) {
      console.error(error);
      setLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to save campaign as draft');
      }
    }
  };

  const onError = () => {
    // if (!validation) {
    //   clearErrors();
    //   setStep(step + 1);
    // }
  };

  const handleNavs = targetStep => {
    if (targetStep < step) {
      setStep(targetStep);
    } else {
      handleSubmit(onSubmitData, onError)();
    }
  };

  useEffect(() => {
    if (!(step === 2 && campaign.campaignExecutions.length === 0)) {
      setExecutionError(null);
    }
  }, [user, step]);

  useEffect(() => {
    setCampaign(initNewCampaign);
    setStep(1);
    reset();
    if (!showAddCampaignModal) {
      autoSaveCampaign();
    } else {
      setIsSaved(false);
    }
  }, [showAddCampaignModal]);

  useEffect(() => {
    campaign.campaignExecutions.forEach(execution => {
      const type = execution.type || ExecutionType.AUTOMATIC;
      execution.campaignCommunications.forEach(communication => {
        let field = `${type}-template-${communication.communicationType}-${execution.index}`;
        setValue(field, communication.templateId);
      });
    });
  }, [campaign.campaignExecutions]);

  return (
    <>
      <Modal
        show={showAddCampaignModal}
        onHide={() => {
          setShowAddCampaignModal(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add campaign
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          <Card
            as={Form}
            noValidate
            onSubmit={e => {
              e.preventDefault();
              console.log('onSubmit');
              handleSubmit(onSubmitData, onError)();
            }}
            className="theme-wizard"
          >
            <Card.Header className={classNames('bg-body-muted pb-2')}>
              <Nav className="justify-content-center">
                {navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
              </Nav>
            </Card.Header>
            <Card.Body className="fw-normal p-0">
              {loading ? (
                <Row className="justify-content-center mb-3 g-3">
                  <Col lg={4} className="d-flex justify-content-center">
                    <Lottie
                      animationData={infiniteLoop}
                      loop={true}
                      style={{ height: '120px', width: '120px' }}
                    />
                  </Col>
                  <h3 className="text-center">Loading...</h3>
                </Row>
              ) : (
                <>
                  {step === 1 && (
                    <div className="px-4 px-md-6 py-4">
                      <CampaignForm
                        register={register}
                        errors={errors}
                        control={control}
                        setValue={setValue}
                        campaign={campaign}
                        setCampaign={setCampaign}
                      />
                    </div>
                  )}
                  {step === 2 && (
                    <div
                      style={{ maxHeight: 700 }}
                      className="overflow-y-auto px-4 px-md-6 pt-4 pb-0"
                    >
                      <ScheduleForm
                        register={register}
                        unregister={unregister}
                        errors={errors}
                        setError={setError}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        clearErrors={clearErrors}
                        campaign={campaign}
                        setCampaign={setCampaign}
                        emailSenders={emailSenders}
                        textSenders={textSenders}
                        templates={templates}
                        segments={segments}
                        groups={groups}
                        contacts={contacts}
                        step={step}
                        setExecutionError={setExecutionError}
                      />
                    </div>
                  )}
                  {step === 3 && (
                    <Review
                      register={register}
                      errors={errors}
                      control={control}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      campaign={campaign}
                      setCampaign={setCampaign}
                      emailSenders={emailSenders}
                      textSenders={textSenders}
                      templates={templates}
                      segments={segments}
                      groups={groups}
                      contacts={contacts}
                    />
                  )}
                  {executionError && (
                    <div
                      className="invalid-feedback d-block px-4 px-md-6 mt-2 "
                      // style={{ marginTop: -28 }}
                    >
                      {executionError}
                    </div>
                  )}
                </>
              )}
            </Card.Body>
            <Card.Footer
              className={classNames('px-md-6 bg-body-muted', {
                'd-none': step === 4,
                ' d-flex': step < 4
              })}
            >
              <IconButton
                variant="link"
                icon={isRTL ? 'chevron-right' : 'chevron-left'}
                iconAlign="left"
                transform="down-1 shrink-4"
                className={classNames('px-0 fw-semibold', {
                  'd-none': step === 1
                })}
                onClick={() => {
                  setStep(step - 1);
                }}
                disabled={loading}
              >
                Prev
              </IconButton>

              <Button
                variant="outline-primary"
                className="ms-auto me-2"
                disabled={loading}
                onClick={handleSaveCampaignAsDraft}
              >
                Save as draft
              </Button>

              <IconButton
                variant="primary"
                className="px-2"
                type="submit"
                icon={isRTL ? 'chevron-left' : 'chevron-right'}
                iconAlign="right"
                transform="down-1 shrink-4"
                disabled={loading}
              >
                {step === 3 ? 'Submit' : 'Next'}
              </IconButton>
            </Card.Footer>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: index < 4 ? step > index : step > 4,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs-10">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs-10 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

AddCampaignModal.propTypes = {
  showAddCampaignModal: PropTypes.bool.isRequired,
  setShowAddCampaignModal: PropTypes.func.isRequired,
  fetchCampaigns: PropTypes.func.isRequired,
  emailSenders: PropTypes.array.isRequired,
  textSenders: PropTypes.array.isRequired,
  templates: PropTypes.array.isRequired,
  segments: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  contacts: PropTypes.array.isRequired
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default AddCampaignModal;
