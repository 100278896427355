import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';
import DailyEmailUsage from 'components/dashboard/DailyEmailUsage';
import SentEmailVolume from 'components/dashboard/SentEmailVolume';
import { useParams } from 'react-router-dom';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import { periodType } from 'data/common';
import Flex from 'components/common/Flex';
import EventSection from 'components/dashboard/events/EventSection';
import Events from 'components/dashboard/events/Events';

const SenderDetailPage = () => {
  const { id } = useParams();
  const [isEmailEventFetching, setIsEmailEventFetching] = useState(false);
  const [isEmailUsageFetching, setIsEmailUsageFetching] = useState(false);
  const [emailEvents, setEmailEvents] = useState([]);
  const [emailUsage, setEmailUsage] = useState([]);

  const [emailUsagePeriod, setEmailUsagePeriod] = useState(periodType.DAY);
  const [emailEventPeriod, setEmailEventPeriod] = useState(periodType.WEEK);

  const fetchData = async (url, optOutUrl, setter, setterFetching) => {
    setterFetching(true);
    try {
      const [response, optOutResponse] = await Promise.all([
        axiosClient.get(url),
        axiosClient.get(optOutUrl)
      ]);

      const rawData = response.data.data;
      const optOutData = optOutResponse.data.data;

      const emailEventsChart = [...rawData];
      emailEventsChart.push({
        eventType: 'Opt out',
        timeSeries: optOutData.data,
        total: optOutData.total
      });

      setter(emailEventsChart);
    } catch (error) {
      console.log(error);
      const message = error.response?.data?.message || 'Error fetching data';
      toast.error(message);
    } finally {
      setterFetching(false);
    }
  };

  const fetchEmailUsage = useCallback(() => {
    if (id && emailUsagePeriod) {
      fetchData(
        `/campaign-events?senderId=${id}&period=${emailUsagePeriod.toLowerCase()}`,
        `/campaign-events/opt-out?senderId=${id}&period=${emailUsagePeriod.toLowerCase()}`,
        setEmailUsage,
        setIsEmailUsageFetching
      );
    }
  }, [id, emailUsagePeriod]);

  const fetchEmailEvent = useCallback(() => {
    if (id && emailEventPeriod) {
      fetchData(
        `/campaign-events?senderId=${id}&period=${emailEventPeriod.toLowerCase()}`,
        `/campaign-events/opt-out?senderId=${id}&period=${emailEventPeriod.toLowerCase()}`,
        setEmailEvents,
        setIsEmailEventFetching
      );
    }
  }, [id, emailEventPeriod]);

  useEffect(() => {
    fetchEmailUsage();
  }, [fetchEmailUsage]);

  useEffect(() => {
    fetchEmailEvent();
  }, [fetchEmailEvent]);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12}>
          <SentEmailVolume />
        </Col>
        <Col md={12}>
          <DailyEmailUsage />
        </Col>
        <Col md={12}>
          <Events
            onPeriodChange={setEmailEventPeriod}
            chartData={emailEvents}
            period={emailEventPeriod}
            loading={isEmailEventFetching}
            className="mb-3"
          />
        </Col>
        <Col md={12}>
          <Card className="h-md-100">
            <Card.Header className="border-bottom">
              <h5 className="mb-2 mt-2">Daily email usage</h5>
              <p className="mb-0 fs-11">
                The following charts show the number of successful send
                requests, as well as the rejection, bounce and complaint rate
              </p>
            </Card.Header>

            <Card.Body as={Flex} alignItems="end" justifyContent="between">
              <div>
                <Form.Select
                  size="sm"
                  className="me-2"
                  onChange={e => {
                    setEmailUsagePeriod(e.target.value);
                  }}
                >
                  <option>{periodType.DAY}</option>
                  <option>{periodType.WEEK}</option>
                  <option>{periodType.MONTH}</option>
                </Form.Select>
              </div>
            </Card.Body>
          </Card>
        </Col>
        {[
          'Send',
          'Delivery',
          'Click',
          'Open',
          'Bounce',
          'Complaint',
          'Opt out'
        ].map((item, index) => (
          <Col md={12} xxl={6} key={`event-section-${index}`}>
            <EventSection
              chartData={
                emailUsage.find(data => data.eventType === item)?.timeSeries ||
                []
              }
              period={emailUsagePeriod}
              loading={isEmailUsageFetching}
              className="mb-3"
              event={item}
              description={`The count of ${item.toLowerCase()} event.`}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default SenderDetailPage;
