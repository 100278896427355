import PropTypes from 'prop-types';
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import SubtleBadge from 'components/common/SubtleBadge';

const Associations = ({ contact }) => {
  return (
    <Card className="h-auto w-100">
      <Card.Header className="bg-body-muted">
        <h5 className="mb-0">Associations</h5>
      </Card.Header>
      <Card.Body className="fs-10">
        {!contact ? (
          <Row className="justify-content-center mb-3 g-3">
            <Col lg={4} className="d-flex justify-content-center">
              <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
            <h3 className="text-center">Loading...</h3>
          </Row>
        ) : (
          <Row>
            <div className="fs-9 fw-normal mb-2">
              <strong>Segments:</strong>
              {contact.segmentNames.length ? (
                <div style={{ lineHeight: 2 }}>
                  {contact.segmentNames.map((segment, index) => (
                    <SubtleBadge key={index} className="me-2 my-1">
                      {segment}
                    </SubtleBadge>
                  ))}
                </div>
              ) : (
                <p className="mb-0">
                  <i
                    style={{
                      fontSize: '12px',
                      color: 'rgba(107, 114, 128, 1)'
                    }}
                  >
                    No relevant segments for this user.
                  </i>
                </p>
              )}
            </div>
            <div className="fs-9 fw-normal">
              <strong>Groups:</strong>
              {contact.groupNames.length ? (
                <div style={{ lineHeight: 2 }}>
                  {contact.groupNames.map((group, index) => (
                    <SubtleBadge key={index} className="me-2 my-1" bg="success">
                      {group}
                    </SubtleBadge>
                  ))}
                </div>
              ) : (
                <p className="mb-0">
                  <i
                    style={{
                      fontSize: '12px',
                      color: 'rgba(107, 114, 128, 1)'
                    }}
                  >
                    This user is not part of any group.
                  </i>
                </p>
              )}
            </div>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

Associations.propTypes = {
  contact: PropTypes.object
};

export default Associations;
