import CardDropdown from 'components/common/CardDropdown';
import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Statistics from './Statistics';
import axiosClient from 'services/axios';
import ProjectTable from './ProjectTable';
import { projectsTableKTN } from 'data/dashboard/projectManagement';

const ProjectStatistics = () => {
  const [data, setData] = useState({
    total: 0,
    statusCounts: [],
    typeCounts: []
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axiosClient.get('/campaigns/statistics');
        setData({
          total: response.data.total || 0,
          statusCounts: response.data.statusCounts || [],
          typeCounts: response.data.typeCounts || []
        });
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Campaign Statistics"
        titleTag="h6"
        endEl={<CardDropdown />}
      />
      <Card.Body className="pt-3">
        <Statistics data={data} loading={loading} />
        <p className="fs-10 mb-2 mt-3">Campaign category's by percentage</p>
        <ProjectTable data={projectsTableKTN} loading={loading} />
      </Card.Body>
    </Card>
  );
};

ProjectStatistics.propTypes = {
  progressBar: PropTypes.array.isRequired,
  projectsTable: PropTypes.array.isRequired,
  projectUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      img: PropTypes.string,
      name: PropTypes.string
    })
  )
};

export default ProjectStatistics;
