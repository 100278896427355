import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  getUser,
  selectUser,
  logout,
  selectToken,
  selectOrganization
} from 'redux/reducer/auth';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import Lottie from 'lottie-react';

const ProtectedResendRoute = () => {
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);
  const organization = useAppSelector(selectOrganization);
  const dispatch = useAppDispatch();

  const fetchUserData = async () => {
    try {
      await dispatch(getUser());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserData();
    } else {
      dispatch(logout());
    }
  }, []);

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (!user) {
    return (
      <Row
        className="justify-content-center align-item-center mb-3 g-3"
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Col
          lg={4}
          className="d-flex justify-content-center"
          style={{ width: '100%' }}
        >
          <Lottie
            animationData={infiniteLoop}
            loop={true}
            style={{ height: '120px', width: '120px' }}
          />
        </Col>
        <h3 className="text-center">Loading...</h3>
      </Row>
    );
  }

  if (user.isVerified && organization) {
    return <Navigate to="/" />;
  } else if (user.isVerified && !organization) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default ProtectedResendRoute;
