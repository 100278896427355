import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Table,
  Card,
  Button,
  Dropdown,
  Spinner,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { FaEdit, FaCaretUp, FaCaretDown } from 'react-icons/fa';
import { GoCheckCircle } from 'react-icons/go';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import './styles.scss';
import { useAppSelector } from 'redux/store';
import { selectUser } from 'redux/reducer/auth';
import UserModal from 'components/users/UserModal';
import CustomPagination from 'components/pagination';
import SubtleBadge from 'components/common/SubtleBadge';
import classNames from 'classnames';
import {
  UserStatusType,
  ProductionAccessStatusType,
  EmailSendingStatus
} from 'data/common';

const ASC = 'ASC';
const DESC = 'DESC';

const UsersPage = () => {
  const user = useAppSelector(selectUser);
  const [isFetching, setIsFetching] = useState(true);

  const [users, setUsers] = useState([]);

  const [showUserModal, setShowUserModal] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [mode, setMode] = useState('ADD');
  const [userDetail, setUserDetail] = useState();
  const [sort, setSort] = useState({
    fieldName: 'full_name',
    order: ASC
  });

  const fetchUsers = async () => {
    setIsFetching(true);
    try {
      const response = await axiosClient.get(
        `/users?page=${page}&limit=${limit}&search=${search}&sort=${sort.fieldName}:${sort.order}`
      );
      setUsers(response.data.users);
      setTotal(response.data.total);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get users');
      }
    }
    setIsFetching(false);
  };

  const getArrowStyles = (fieldName, order) => {
    if (sort.fieldName === fieldName && sort.order === order) {
      return {
        color: '#748194'
      };
    } else {
      return {
        color: '#d8e2ef'
      };
    }
  };

  const onSortChange = fieldName => {
    if (sort.fieldName === fieldName) {
      setSort({
        fieldName,
        order: sort.order === ASC ? DESC : ASC
      });
    } else {
      setSort({
        fieldName,
        order: ASC
      });
    }
  };

  useEffect(() => {
    if (limit) {
      setPageCount(Math.ceil(total / limit));
    }
  }, [limit, total]);

  useEffect(() => {
    if (user) {
      fetchUsers();
    }
  }, [limit, page, user, sort]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
      setPage(1);
    }, 300);

    // Clear the timeout if search changes (before the delay ends)
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (user) {
      fetchUsers();
    }
  }, [debouncedSearch]);

  return (
    <>
      <h4 className="">Users</h4>
      <Row className="g-3 mb-3">
        <Col md={12}>
          <Card className="overflow-hidden">
            <Card.Header className="col-12 d-flex flex-wrap gap-2 justify-content-between border-bottom">
              <input
                className="input-value search-input-value user-input-value"
                label="Value"
                name="value-0"
                type="text"
                placeholder="Search for full name, email, phone number"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </Card.Header>
            <Card.Body>
              <Table
                responsive
                style={{
                  height:
                    isFetching || users.length < 0 ? 'calc(100vh - 250px)' : ''
                }}
              >
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('full_name')}
                    >
                      <div className="d-flex" style={{ minWidth: 130 }}>
                        Full name
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('full_name', ASC)} />
                          <FaCaretDown
                            style={getArrowStyles('full_name', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('email')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 220 }}
                      >
                        Email
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('email', ASC)} />
                          <FaCaretDown style={getArrowStyles('email', DESC)} />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('phone_number')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 130 }}
                      >
                        Phone number
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('phone_number', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('phone_number', DESC)}
                          />
                        </span>
                      </div>
                    </th>

                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('aws_account_number')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 200 }}
                      >
                        Aws account number
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('aws_account_number', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('aws_account_number', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('tier_plan')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 80 }}
                      >
                        Tier plan
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('tier_plan', ASC)} />
                          <FaCaretDown
                            style={getArrowStyles('tier_plan', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('is_verified')}
                    >
                      <div className="d-flex justify-content-between">
                        Verified
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('is_verified', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('is_verified', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('status')}
                    >
                      <div className="d-flex justify-content-between">
                        Status
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('status', ASC)} />
                          <FaCaretDown style={getArrowStyles('status', DESC)} />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('business_name')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 150 }}
                      >
                        Business name
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('business_name', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('business_name', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('business_address')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 200 }}
                      >
                        Business address
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('business_address', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('business_address', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('business_type')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 150 }}
                      >
                        Business type
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('business_type', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('business_type', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('state')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 100 }}
                      >
                        State
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('state', ASC)} />
                          <FaCaretDown style={getArrowStyles('state', DESC)} />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('zip_code')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 80 }}
                      >
                        Zip code
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('zip_code', ASC)} />
                          <FaCaretDown
                            style={getArrowStyles('zip_code', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('city')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 100 }}
                      >
                        City
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('city', ASC)} />
                          <FaCaretDown style={getArrowStyles('city', DESC)} />
                        </span>
                      </div>
                    </th>

                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('website')}
                    >
                      <div className="d-flex justify-content-between">
                        Website
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('website', ASC)} />
                          <FaCaretDown
                            style={getArrowStyles('website', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('production_access_status')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 200 }}
                      >
                        Production access status
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles(
                              'production_access_status',
                              ASC
                            )}
                          />
                          <FaCaretDown
                            style={getArrowStyles(
                              'production_access_status',
                              DESC
                            )}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('ses_setup_complete')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 200 }}
                      >
                        Ses setup complete
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('ses_setup_complete', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('ses_setup_complete', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('email_type')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 100 }}
                      >
                        Email type
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('email_type', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('email_type', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('email_use_case')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 180 }}
                      >
                        Email use case
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('email_use_case', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('email_use_case', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('sending_limit')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 120 }}
                      >
                        Sending limit
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('sending_limit', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('sending_limit', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('email_sending_status')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 200 }}
                      >
                        Email sending status
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('email_sending_status', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('email_sending_status', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('email_sending_enabled')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 200 }}
                      >
                        Email sending enabled
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('email_sending_enabled', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles(
                              'email_sending_enabled',
                              DESC
                            )}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('text_sending_enabled')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 200 }}
                      >
                        Text sending enabled
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('text_sending_enabled', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('text_sending_enabled', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isFetching ? (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : users.length > 0 ? (
                    users.map((user, index) => (
                      <tr key={`user-${index}`}>
                        <td>{user.fullName}</td>
                        <td>{user.email}</td>
                        <td>{user.phoneNumber}</td>
                        <td>{user.awsAccountNumber}</td>

                        <td>
                          <SubtleBadge
                            bg={classNames({
                              success: user.tierPlan === 'Tier 1',
                              info: user.tierPlan === 'Tier 2',
                              warning: user.tierPlan === 'Tier 3'
                            })}
                            className="me-1"
                          >
                            {user.tierPlan}
                          </SubtleBadge>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center">
                            {user.isVerified ? (
                              <GoCheckCircle className="text-success fs-9" />
                            ) : (
                              <IoIosCloseCircleOutline className="text-danger fs-8" />
                            )}
                          </div>
                        </td>
                        <td>
                          <SubtleBadge
                            bg={classNames({
                              primary: user.status === UserStatusType.ACTIVE,
                              secondary: user.status === UserStatusType.DISABLED
                            })}
                            className="me-1"
                          >
                            {user.status}
                          </SubtleBadge>
                        </td>
                        <td>{user.businessName}</td>
                        <td>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`businessAddress-${index}`}>
                                {user.businessAddress}
                              </Tooltip>
                            }
                          >
                            <span>
                              {user.businessAddress.length > 20
                                ? user.businessAddress.substring(0, 20) + '...'
                                : user.businessAddress}
                            </span>
                          </OverlayTrigger>
                        </td>
                        <td>{user.businessType}</td>
                        <td>{user.state}</td>
                        <td>{user.zipCode}</td>
                        <td>{user.city}</td>
                        <td>{user.website}</td>

                        <td>
                          <div className="d-flex justify-content-center">
                            <SubtleBadge
                              bg={classNames({
                                success:
                                  user.productionAccessStatus ===
                                  ProductionAccessStatusType.ACTIVE,
                                info:
                                  user.productionAccessStatus ===
                                  ProductionAccessStatusType.INPROGRESS
                              })}
                              className="me-1"
                            >
                              {user.productionAccessStatus}
                            </SubtleBadge>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center">
                            {user.sesSetupComplete ? (
                              <GoCheckCircle className="text-success fs-9" />
                            ) : (
                              <IoIosCloseCircleOutline className="text-danger fs-8" />
                            )}
                          </div>
                        </td>
                        <td>
                          <SubtleBadge
                            bg={classNames({
                              success: user.emailType === 'Marketing',
                              info: user.emailType === 'Transactional'
                            })}
                            className="me-1"
                          >
                            {user.emailType}
                          </SubtleBadge>
                        </td>
                        <td>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`businessAddress-${index}`}>
                                {user.emailUseCase}
                              </Tooltip>
                            }
                          >
                            <span>
                              {user.emailUseCase &&
                              user.emailUseCase.length > 20
                                ? user.emailUseCase.substring(0, 20) + '...'
                                : user.emailUseCase}
                            </span>
                          </OverlayTrigger>
                        </td>
                        <td>{user.sendingLimit.toLocaleString()}</td>
                        <td>
                          <SubtleBadge
                            bg={classNames({
                              success:
                                user.emailSendingStatus ===
                                EmailSendingStatus.ACTIVE,
                              secondary:
                                user.emailSendingStatus ===
                                EmailSendingStatus.SUSPENDED,
                              danger:
                                user.emailSendingStatus ===
                                EmailSendingStatus.WARNED,
                              warning:
                                user.emailSendingStatus ===
                                EmailSendingStatus.PRE_WARNED
                            })}
                            className="me-1"
                          >
                            {user.emailSendingStatus}
                          </SubtleBadge>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center">
                            {user.emailSendingEnabled ? (
                              <GoCheckCircle className="text-success fs-9" />
                            ) : (
                              <IoIosCloseCircleOutline className="text-danger fs-8" />
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center">
                            {user.textSendingEnabled ? (
                              <GoCheckCircle className="text-success fs-9" />
                            ) : (
                              <IoIosCloseCircleOutline className="text-danger fs-8" />
                            )}
                          </div>
                        </td>
                        <td className="px-0">
                          <div className="d-flex gap-2 me-1">
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  style={{ position: 'fixed', zIndex: 1000 }}
                                  id={`edit-button-${index}`}
                                >
                                  Edit
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="outline-info"
                                onClick={e => {
                                  e.stopPropagation();
                                  setShowUserModal(true);
                                  setMode('EDIT');
                                  setUserDetail(user);
                                }}
                              >
                                <FaEdit />
                              </Button>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        No users found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="position-relative">
                <CustomPagination
                  page={page}
                  pageCount={pageCount}
                  setPage={setPage}
                />
                {users.length > 0 && (
                  <Dropdown
                    className="segment-dropdown position-absolute top-0 end-0"
                    style={{ minWidth: '70px' }}
                    onSelect={e => {
                      setLimit(e);
                      setPage(1);
                    }}
                  >
                    <Dropdown.Toggle variant="falcon-default text-capitalize">
                      {limit}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="py-2">
                      {[10, 20, 50, 100].map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={item}
                          className="text-capitalize"
                        >
                          {item}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <UserModal
        showUserModal={showUserModal}
        setShowUserModal={setShowUserModal}
        fetchUsers={fetchUsers}
        userDetail={userDetail}
        mode={mode}
      />
    </>
  );
};

export default UsersPage;
