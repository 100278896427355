import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import LoginForm from 'components/authentication/LoginForm';

const Login = () => (
  <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4 mx-auto">
    <Card>
      <Card.Body className="p-4 p-sm-5">
        <Flex justifyContent="between" alignItems="center" className="mb-2">
          <h5>Log in</h5>
          <p className="fs-10 text-600 mb-0">
            or <Link to="/register">Create an account</Link>
          </p>
        </Flex>
        <LoginForm />
      </Card.Body>
    </Card>
  </Col>
);

export default Login;
